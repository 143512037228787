import { createGlobalStyle } from 'styled-components';
import theme from '@styles/theme';

export const GlobalStyle = createGlobalStyle`  
  body{
    padding : 0;
    margin : 0;
    font-family: ${theme.fonts['K2D-Light']};
    scroll-behavior: smooth;
  }
  label{
    cursor: pointer;
    user-select: none;
  }
  textarea {
    border: 1px solid #e3e8f0;
    outline: none;
    padding: 10px 16px;
    width: 100%;
    font-size: 16px;
    color: ${theme.color.text_chinh};
    font-family: ${theme.fonts['K2D-Light']};
  }
  button {
    outline: none;
    cursor: pointer;
    &:disabled{
      cursor: not-allowed;
    }
  }
  img{
    display: inline-block;
  }

  /* @media screen and (max-width: 766px) {
  * {
    &:hover, &:link, &:visited{
      transform : unset;
    }
  } */
}
`;
