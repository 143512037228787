import Table from '@components/Table';
import Pagination from 'rc-pagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTableRow from './DataTableRow';
import { changePage } from './store/actions';
import { selectOrderStore } from './store/selecters';

const THead = [
  '',
  'Mã đơn hàng',
  'Thời gian yêu cầu',
  // 'Sản phẩm',
  // 'Số lượng',
  // 'Đơn giá',
  // 'Thành tiền',
  'Giảm giá',
  'Phí giao dịch',
  'Tổng tiền thanh toán',
  'Trạng thái',
];

export default function DataTable({ loading }: { loading: boolean }) {
  const { data, pagination, total } = useSelector(selectOrderStore);
  const dispatch = useDispatch();
  
  return (
    <>
      <Table
        style={{ border: true }}
        isLoading={loading}
        thead={THead.map((h, index) => (
          <th key={'thead' + index}>{h}</th>
        ))}
        tbody={data.map((d: any) => {
          return <DataTableRow product={d} />;
        })}
      />
      {(total || 0) / pagination.pageSize >= 1 && (
        <Pagination
          onChange={(pageNumber: number, pageSize: number) => {
            dispatch(changePage({ ...pagination, pageNumber, pageSize }));
          }}
          pageSize={pagination.pageSize}
          current={pagination.pageNumber}
          total={total}
        />
      )
      }
    </>
  );
}
