import Table from '@components/Table';
import Pagination from 'rc-pagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRefundStore } from './store/selecters';
import { setPaging } from './store/actions';
import { convertDateWithTime, convertStatus } from '@helpers/format';

const THead = [
  'Mã giao dịch',
  'Mã đơn hàng',
  'Trạng thái',
  'Lý do yêu cầu',
  'Thời gian',
];

export default function DataTable({ loading }: { loading: boolean }) {
  const { data, pagination, total } = useSelector(selectRefundStore);
  const dispatch = useDispatch();

  return (
    <>
      <Table
        style={{ border: true }}
        isLoading={loading}
        thead={THead.map((h, index) => (
          <th key={'thead' + index}>{h}</th>
        ))}
        tbody={data.map((d, index) => {
          return (
            <tr key={'tr' + index}>
              <td>{d?.code}</td>
              <td>{d?.transaction?.paymentLink?.codeBill}</td>
              <td>{convertStatus(d?.status)}</td>
              <td>{d?.content}</td>
              <td>{convertDateWithTime(d?.createdAt)}</td>
            </tr>
          );
        })}
      />
      {(total || 0) / pagination.pageSize >= 1 && (
        <Pagination
          onChange={(pageNumber: number) => {
            dispatch(setPaging({ ...pagination, pageNumber }));
          }}
          current={pagination.pageNumber}
          total={total}
        />
      )}
    </>
  );
}
