import React from 'react';
import styled from 'styled-components';

const padToTwo = (number: number) => {
  if (number < 10) return '0' + number;
  return number;
};

export default function CountDownTimer({ time }: { time: number }) {
  return (
    <SCountDown>
      <span>{padToTwo(Math.floor(time / 60))}</span> :{' '}
      <span>
        {padToTwo(Math.floor((time - Math.floor(time / 60) * 60) % 60))}
      </span>
    </SCountDown>
  );
}

const SCountDown = styled.div`
  color: red;
  font-size: 14px;
  transition: all 0.3s ease;
  word-spacing: 1px;
`;
