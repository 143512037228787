import Table from '@components/Table';
import Pagination from 'rc-pagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductsStore } from './store/selecters';
import { setPaging } from './store/actions';
import DataTableRow from './DataTableRow';

const THead = [
  '',
  'Tên sản phẩm',
  'Danh mục',
  'Giá',
  'Tổng bán',
  'Doanh thu',
  'Phê duyệt',
  'Trạng thái',
  'Số lượng',
  'Tạo đơn hàng',
  '',
];

export default function DataTable({ loading }: { loading: boolean }) {
  const { data, pagination, total } = useSelector(selectProductsStore);
  const dispatch = useDispatch();
  return (
    <>
      <Table
        style={{ border: true }}
        isLoading={loading}
        thead={THead.map((h, index) => (
          <th key={'thead' + index}>{h}</th>
        ))}
        tbody={data.map(d => {
          return <DataTableRow product={d} />;
        })}
      />
      {(total || 0) / pagination.pageSize >= 1 && (
        <Pagination
          onChange={(pageNumber: number, pageSize: number) => {
            dispatch(setPaging({ ...pagination, pageNumber, pageSize }));
          }}
          pageSize={pagination.pageSize}
          current={pagination.pageNumber}
          total={total}
        />
      )}
    </>
  );
}
