import React from 'react';
import Box, { SBox } from './Box';
import IJoinNow from '@assets/images/join-now.svg';
import Button from '@components/Button';
import { SJoinNow } from './style';
// import { useHistory } from 'react-router';
import styled from 'styled-components';

export default function JoinNow() {
  // const history = useHistory();
  return (
    <SJoinNowWrap>
      <Box style={{ marginTop: '135px' }}>
        <SJoinNow>
          <img src={IJoinNow} alt="join now" />
          <p className="text">
            Bytepay - Giao dịch an toàn, tiết kiệm thời gian.
          </p>
          {/* <Button>JOIN NOW</Button> */}
        </SJoinNow>
      </Box>
    </SJoinNowWrap>
  );
}

const SJoinNowWrap = styled.div`
  ${Button} {
    border-radius: 8px;
  }

  @media (max-width: 1199px) {
    height: 100%;

    ${SBox} {
      margin: 0 !important;
      overflow: hidden;
      height: 100%;

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        ${SJoinNow} {
          height: 100%;
          min-height: 220px;

          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .text,
      ${Button} {
        margin: 0;
      }

      svg,
      img {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
