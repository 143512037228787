import styled from "styled-components";

export const SShopInfo = styled.div`
margin: 24px;
padding-bottom: 24px;

.title{

}
.form{

}
.upload-img{
  

  .img{
    border: 1px solid gray;
    position: relative;
    width: 200px;
    height: 200px;

    img{
      border-radius: 3px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    input{
      top:0;
      left:0;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
.form-input{
margin-bottom: 18px;
.label{
  margin-bottom:8px;
  font-weight: 600;
}
.input{}
}
` 