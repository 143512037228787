import Button from '@components/Button';
import React from 'react';
import { SHandles } from './style';
import Print from '@assets/images/print-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import ShowSelect from '@components/Input/ShowSelect';
import { TPagination } from '@configs/types';
import { handleExport, setPaging } from './store/actions';
import FileSaver from 'file-saver';
import { HandleError } from '@components/HandleResponse';
import { selectRefundStore } from './store/selecters';

export default function Handles({ pagination }: { pagination: TPagination }) {
  const dispatch = useDispatch();
  const { search } = useSelector(selectRefundStore);

  const onExport = () => {
    handleExport({
      ...search
    })
      .then(res => {
        FileSaver.saveAs(res.data, 'paymentlink.xlsx');
      })
      .catch(HandleError);
  };

  return (
    <SHandles>
      <Button className="print" onClick={onExport}>
        <img src={Print} alt="export" />
      </Button>

      <div className="select">
        <label>Hiển thị</label>
        <ShowSelect
          defaultValue={{
            label: pagination.pageSize.toString(),
            value: pagination.pageSize.toString(),
          }}
          onChange={val => {
            let pageSize = pagination.pageSize;
            if (val && parseInt(val.value) !== pageSize) {
              pageSize = parseInt(val.value);
              dispatch(setPaging({ ...pagination, pageSize }));
            }
          }}
        />
      </div>
    </SHandles>
  );
}
