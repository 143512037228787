import { Alert } from '@components/Alert';
import Input, { Checkbox, DatePicker } from '@components/Input';
import theme from '@styles/theme';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import IInforClient from '@assets/images/infor_client.svg';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError, HandleSuccess } from '@components/HandleResponse';
import { Controller, useForm } from 'react-hook-form';
import { Button, Text } from '@components/index';
import VALIDATE from '@helpers/validate';
import { ErrorMessage } from '@hookform/error-message';
import { convertDateWithTime, convertExpireDate, money } from '@helpers/format';

type Props = {
  data: { [key in string]: any };
  changeStatus: any;
};

const keys = [
  { name: 'Mục đích thanh toán', key: 'paymentPurpose' },
  { name: 'Tình trạng', key: 'status' },
  { name: 'Số tiền', key: 'money' },
  { name: 'Đơn vị ', key: 'currency' },
  { name: 'Số tiền thanh toán', key: 'transactionsMoney' },
  { name: 'Mã hóa đơn', key: 'codeBill' },
  { name: 'Người tạo', key: 'merchant' },
  { name: 'Thời gian tạo', key: 'createdAt', type: 'date' },
  { name: 'Thời gian kích hoạt', key: 'updatedAt', type: 'date' },
  { name: 'Ngày hết hạn', key: 'expireDate' },
  { name: 'Ghi chú', key: 'note' },
];

const keyinfor = [
  { name: 'Tên  khách hàng', key: 'userName' },
  { name: 'Số điện thoại', key: 'mobile' },
  { name: 'Ngày sinh', key: 'birthday' },
  { name: 'Địa chỉ', key: 'address' },
  // { name: 'Tên sản phẩm', key: 'codeProduct' },
  { name: 'Đơn hàng', key: 'link' },
];

export default function DetailPayment({ data, changeStatus }: Props) {
  const [customerInfo, setcustomerInfo] = useState<any>();
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [nameMerchant, setnameMerchant] = useState('');

  useEffect(() => {
    requestToken({
      method: 'GET',
      url: API_URL.PAYMENTLINK.GET_ID(data.id),
    }).then(res => {
      const customerInfo = JSON.parse(res.data.customerInfo) || {};
      if (customerInfo['birthday']) {
        setValue('birthday', new Date(customerInfo['birthday']));
      }

      setcustomerInfo(customerInfo);

      let name = `${res.data.merchant.firstName} ${res.data.merchant.lastName} (Bạn)`;
      if (res.data.paymentLinkType === 'fixed') {
        name = customerInfo.userName;
      }
      setnameMerchant(name);
    });
    // .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const obSumit = (dataForm: any) => {
    // console.log('dataForm', dataForm);
    requestInterToken({
      method: 'PUT',
      url: `${API_URL.CUSTOMER.PUT}/${customerInfo.id}`,
      data: {
        urlPaymentLink: data?.url,
        userName: dataForm?.userName,
        mobile: dataForm?.mobile,
        birthday: dataForm?.birthday && new Date(dataForm?.birthday),
        address: dataForm?.address,
        codeProduct: dataForm?.codeProduct,
      },
    })
      .then(HandleSuccess)
      .catch(HandleError);
  };

  return (
    <SDetailPayment>
      {data ? (
        <>
          <CopyToClipboard
            text={`${window.location.origin}/payment/${data?.url}`}
            onCopy={() => Alert({ icon: 'success', name: 'Đã sao chép.' })}
          >
            <h2 className="link">{`${window.location.origin}/payment/${data?.url}`}</h2>
          </CopyToClipboard>

          <div className="list">
            <Row>
              {keys.map((k, index) => {
                if (k.key === 'status') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <SInputStatus>
                          <Checkbox
                            onClick={() => changeStatus()}
                            checked={data[k.key] === 'active' ? true : false}
                          />
                          <span>(Vô hiệu hóa Đơn hàng)</span>
                        </SInputStatus>
                      </div>
                    </Col>
                  );
                } else if (k.key === 'merchant') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput defaultValue={nameMerchant} disabled />
                        </div>
                      </div>
                    </Col>
                  );
                } else if (k.type === 'date') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput
                            defaultValue={convertDateWithTime(data[k.key])}
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  );
                } else if (k.key === 'expireDate') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput
                            defaultValue={
                              data?.notExpire
                                ? 'Không hết hạn'
                                : convertExpireDate(data[k.key])
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  );
                } else if (k.key === 'money' || k.key === 'transactionsMoney') {
                  return (
                    <Col sm={6}>
                      <div className="item">
                        <div className="text">{k.name}</div>
                        <div className="value">
                          <SInput
                            defaultValue={money(data[k.key], undefined, true)}
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  );
                }
                return (
                  <Col sm={6}>
                    <div className="item">
                      <div className="text">{k.name}</div>
                      <div className="value">
                        <SInput defaultValue={data[k.key]} disabled />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>

          {/* Thông tin khách hàng */}
          <div className="info">
            <div className="title">
              <img src={IInforClient} alt="icon" />
              <p>Thông tin khách hàng</p>
            </div>

            <div className="list">
              <form onSubmit={handleSubmit(obSumit)}>
                <Row>
                  {keyinfor.map((k, index) => {
                    if (k.key === 'link') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <SInput defaultValue={data?.url} disabled />
                            </div>
                          </div>
                        </Col>
                      );
                    } else if (k.key === 'birthday') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <Controller
                                control={control}
                                name={k.key}
                                render={props => (
                                  <DatePicker
                                    {...props}
                                    onDayChange={props.onChange}
                                  />
                                )}
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={k.key}
                              render={Text.ErrorMessage}
                            />
                          </div>
                        </Col>
                      );
                    } else if (k.key === 'userName') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <SInput
                                ref={register({
                                  required: VALIDATE.REQUIRE,
                                  maxLength: {
                                    value: 255,
                                    message: 'Tối đa 255 ký tự.',
                                  },
                                })}
                                name={k.key}
                                defaultValue={
                                  customerInfo ? customerInfo[k.key] : ''
                                }
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={k.key}
                              render={Text.ErrorMessage}
                            />
                          </div>
                        </Col>
                      );
                    } else if (k.key === 'codeProduct') {
                      return (
                        <Col key={index} sm={6}>
                          <div className="item">
                            <div className="text">{k.name}</div>
                            <div className="value">
                              <SInput
                                ref={register({
                                  required: false,
                                })}
                                name={k.key}
                                defaultValue={
                                  customerInfo ? customerInfo[k.key] : ''
                                }
                              />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name={k.key}
                              render={Text.ErrorMessage}
                            />
                          </div>
                        </Col>
                      );
                    }
                    return (
                      <Col key={index} sm={6}>
                        <div className="item">
                          <div className="text">{k.name}</div>
                          <div className="value">
                            <SInput
                              ref={register({
                                required: VALIDATE.REQUIRE,
                                maxLength: {
                                  value: 255,
                                  message: 'Tối đa 255 ký tự.',
                                },
                                minLength: {
                                  value: 6,
                                  message: 'Tối thiểu 6 ký tự.',
                                },
                              })}
                              name={k.key}
                              defaultValue={
                                customerInfo ? customerInfo[k.key] : ''
                              }
                            />
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={k.key}
                            render={Text.ErrorMessage}
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <Button
                  type="submit"
                  style={{ margin: '32px auto 0', display: 'block' }}
                >
                  Chỉnh sửa Thông tin khách hàng
                </Button>
              </form>
            </div>
          </div>
        </>
      ) : (
        'Không có thông tin.'
      )}
    </SDetailPayment>
  );
}

const SDetailPayment = styled.div`
  .link {
    background: #e4effa;
    border-radius: 2px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;

    font-style: normal;
    font-family: ${theme.fonts['K2D-Regular']};
    font-size: 20px;
    line-height: 23px;
    color: #4a70ff;
    word-break: break-all;
    min-height: 52px;
    height: unset;
    padding: 8px;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    .item {
      /* display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 450px;
        width: 100%; */
      margin-top: 18px;

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #3a3b42;
        margin-bottom: 4px;
      }
      .value {
        input {
          font-family: ${theme.fonts['K2D-Regular']};
          font-size: 16px;
          line-height: 19px;
          /* identical to box height */

          /* text chinh */

          color: #3a3b42;
        }
      }
    }
  }

  .info {
    margin-top: 56px;
    .title {
      display: flex;
      align-items: center;
      p {
        font-family: ${theme.fonts['Roboto-Bold']};
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        margin-left: 8px;
        color: #3a3b42;
      }
    }
    .list {
      margin-top: 13px;
    }
  }
  @media (max-width: 767px) {
    .link {
      word-break: break-all;
      min-height: 52px;
      padding: 15px;
      font-size: 15px;
    }
    .list {
      .item {
        flex-direction: column;
        .text {
          align-items: flex-start;
          width: 100%;
          padding-bottom: 16px;
        }
      }
    }
  }
`;

const SInput = styled(Input)`
  &:disabled {
    background: white;
  }
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b42;
`;

const SInputStatus = styled.div`
  display: flex;
  border: 1px solid #e3e8f0;
  box-sizing: border-box;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  align-items: center;
  padding: 0 15px;
  /* max-width: 236px; */
  justify-content: space-between;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #5f6169;
  }
`;
