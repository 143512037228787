import { Alert } from '@components/Alert';
// import { Radio } from '@components/Input';
import React, { useMemo, useState } from 'react';
import { MSG_DELETE_SUCCESS } from './store/constants';
// import imageSkeleton from '@assets/images/image-skeleton.png';
import MoreOptions from '@components/MoreOptions';
import { useHistory } from 'react-router-dom';
import Delete from '@assets/images/delete.svg';
import { SDeleteModal } from './style';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { useDispatch } from 'react-redux';
import {
  handleDeleteProduct,
  handleDuplicate,
  onRefresh,
} from './store/actions';
import { HandleError } from '@components/HandleResponse';
import { money } from '@helpers/format';
import IPlusProduct from '@assets/images/plus_product.svg';
import ModalProduct from './ModalProduct';

export default function DataTableRow({ product }: { product: any }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isShowing, toggleModal] = useState(false);

  const isActive =
    product?.status === 'active' &&
    product?.adminApprove === 'active' &&
    product?.category?.status;

  // const handleDisable = () => {
  //   handleDisableProduct(product)
  //     .then(() => {
  //       dispatch(onRefresh());
  //       Alert({ name: MSG_DISABLE_SUCCESS, icon: 'success' });
  //     })
  //     .catch(HandleError);
  // };

  const handleDelete = () => {
    handleDeleteProduct(product)
      .then(() => {
        dispatch(onRefresh());
        Alert({ name: MSG_DELETE_SUCCESS, icon: 'success' });
      })
      .catch(HandleError);
  };

  const options = useMemo(
    () => [
      {
        name: <span>Nhân bản</span>,
        callback: (e: any) => {
          handleDuplicate(product.id)
            .then(() => {
              dispatch(onRefresh());
              Alert({ name: 'Nhân bản sản phẩm thành công', icon: 'success' });
            })
            .catch(HandleError);
        },
      },
      {
        name: <span>Chỉnh sửa</span>,
        callback: (e: any) => {
          toggleModal(true);
        },
      },
      {
        name: <span>Xóa</span>,
        callback: (e: any) => setIsOpen(true),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product],
  );

  const [isOpen, setIsOpen] = useState(false);

  return (
    <tr key={product.id}>
      <td>{/* <Radio name="product" width={12} height={12} /> */}</td>
      <td onClick={() => toggleModal(!isShowing)} style={{ cursor: 'pointer' }}>
        {product.name}
      </td>
      <td>{product?.category?.name || ''}</td>
      <td>{money(product.price, undefined, true)}</td>
      <td>{product.total}</td>
      <td>
        {money(
          (parseInt(product.total) * parseInt(product.price)).toString(),
          undefined,
          true,
        )}
      </td>
      <td>
        {product?.adminApprove === 'lock'
          ? 'Chưa duyệt'
          : product?.adminApprove === 'active'
          ? 'Đã duyệt'
          : 'Từ chối'}
      </td>
      <td>
        {product?.status === 'active' &&
        product?.category?.status &&
        product?.adminApprove === 'active'
          ? 'Hoạt động'
          : 'Không hoạt động'}
      </td>

      <td>{product?.quantity}</td>
      <td
        onClick={() => {
          if (isActive && product?.quantity) {
            history.push('/dashboard/create-payment-links', {
              product,
            });
          }
        }}
      >
        {isActive && product?.quantity ? (
          <img
            style={{ cursor: 'pointer' }}
            src={IPlusProduct}
            alt="icon_plus"
          />
        ) : (
          ''
        )}
      </td>
      <td>
        <MoreOptions
          dotSize={18}
          options={options}
          style={{
            position: 'top',
          }}
        />
        <Modal
          title=""
          isShowing={isOpen}
          toggleModal={setIsOpen}
          overwriteChild={{ style: { maxWidth: '620px' } }}
        >
          <SDeleteModal>
            <img src={Delete} alt="delete" />
            <p>Bạn có muốn xóa sản phẩm khỏi danh sách ?</p>
            <div className="group-buttons">
              <Button
                className="cancel"
                color="gray"
                type="button"
                onClick={() => setIsOpen(false)}
              >
                Hủy bỏ
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleDelete();
                  setIsOpen(false);
                }}
              >
                Đồng ý
              </Button>
            </div>
          </SDeleteModal>
        </Modal>
      </td>

      {/* update product */}
      {isShowing && (
        <ModalProduct
          open={isShowing}
          setOpen={toggleModal}
          productData={product}
        />
      )}
    </tr>
  );
}
