import ErrorBound from '@components/ErrorBound';
import HomeLayout from '@containers/CLayout/LayoutNews/HomeLayout';
import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import WrapAuthenticationDetail from './style';
import IPay1 from '@assets/images/instructions/pay/Picture1.png';
import IPay2 from '@assets/images/instructions/pay/Picture2.png';
import IPay3 from '@assets/images/instructions/pay/Picture3.png';
import IPay4 from '@assets/images/instructions/pay/Picture4.png';
import IPay5 from '@assets/images/instructions/pay/Picture5.png';
import IPay6 from '@assets/images/instructions/pay/Picture6.png';
import IPay7 from '@assets/images/instructions/pay/Picture7.png';


export default function PayDetail() {
  return (
    <HomeLayout>
      <ErrorBound>
        <WrapAuthenticationDetail>
          <Container>
            <div className="wrap_inside">
              <div className="title">hướng dẫn chi tiết</div>

              <div className="name">Hướng dẫn thanh toán đơn hàng</div>

              <div className="steps">
                <div className="step_item">
                  <div className="step_item_detail">
                    <p><span>1</span>Vào Cửa hàng </p>
                  </div>
                  <img src={IPay1} alt="payment" />
                </div>
                
                <div className="step_item">
                  <div className="step_item_detail">
                    <p><span>2</span>Chọn sản phẩm yêu thích</p>
                  </div>
                  <img src={IPay2} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <p><span>3</span>Xem thông tin chi tiết sản phẩm</p>
                  </div>
                  <img src={IPay3} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <p><span>4</span>Thực hiện mua ngay hoặc thêm sản phẩm vào giỏ hàng</p>
                  </div>
                  <img src={IPay4} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <p><span>5</span>Nhập thông tin người mua tại giỏ hàng và tiến hành thanh toán</p>
                  </div>
                  <img src={IPay5} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <p><span>6</span>Kiểm tra thông tin đơn hàng & chọn cổng thanh toán</p>
                  </div>
                  <img src={IPay6} alt="payment" />
                </div>

                <div className="step_item">
                  <div className="step_item_detail">
                    <p><span>7</span>Xác nhận thông tin thanh toán để chuyển qua kênh thanh toán</p>
                  </div>
                  <img src={IPay7} alt="payment" />
                </div>

                
              </div>
            </div>
          </Container>
        </WrapAuthenticationDetail>
      </ErrorBound>
    </HomeLayout>
  );
}
