/*
*
* Order
* make by phamthainb
*/

import React, { memo, useEffect, useState } from 'react';
import ErrorBound from '@components/ErrorBound';
import useInjectReducer from '@redux/useInjectReducer';
import reducersOrder from './store/reducers';
import WrapOrder from './style';
import Section from '@containers/CLayout/LayoutDashBroad/Section';
import Search from './Search';
import Handle from "./Handle";
import DataTable from "./DataTable";
import { selectOrderStore } from './store/selecters';
import { getDataOrder, setDataOrder } from './store/actions';
import { HandleError } from '@components/HandleResponse';
import { useSelector, useDispatch } from 'react-redux';

interface Props { }

// eslint-disable-next-line
function Order({ }: Props) {
  useInjectReducer('Order', reducersOrder);


  const { search, pagination, reloadData } = useSelector(
    selectOrderStore
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    getDataOrder({ search, pagination })
      .then(res => {
        dispatch(setDataOrder({ data: res.data.data, total: res.data.meta.total }));
      })
      .catch(HandleError)
      .finally(() => setLoading(false));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pagination, reloadData]);

  return (
    <ErrorBound>
      <WrapOrder>
        <Section title="Quản lý Đơn hàng">
          <>
            <Search />
            <Handle />
            <DataTable loading={loading} />
          </>
        </Section>
      </WrapOrder>
    </ErrorBound>
  );
}
export default memo(Order);