import React, { HTMLAttributes, ReactChild, ReactChildren } from 'react';
import styled from 'styled-components';
import theme from '@styles/theme';

interface Props extends HTMLAttributes<any> {
  title?: any;
  children?: ReactChild | ReactChildren;
}

export default function Box({ title, children, ...props }: Props) {
  return (
    <SBox {...props}>
      {title && (
        <>
          <h3 className="title">{title}</h3>
          <span className="mark" />
        </>
      )}
      <div className="content">{children}</div>
    </SBox>
  );
}

export const SBox = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 32px 25px;
  position: relative;
  /* margin: 15px 0; */

  h3.title {
    font-family: ${theme.fonts['K2D-Light']};
    font-size: 24px;
    line-height: 28px;
    color: ${theme.color.text_chinh};
    margin: 0;
    padding-bottom: 24px;
  }

  .mark {
    height: 1px;
    width: calc(100% + 50px);
    background: #e3e8f0;
    display: block;
    transform: translateX(-25px);
  }
  .content {
  }

  @media (max-width: 1199px) {
    margin: 0;
  }
`;
