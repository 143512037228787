import { Alert } from '@components/Alert';
import Button from '@components/Button';
import { HandleError } from '@components/HandleResponse';
import { Checkbox, Input, Modal, Text } from '@components/index';
import VALIDATE from '@helpers/validate';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  handleGetOneProduct,
  handlePostProduct,
  handleUpdateProduct,
  onRefresh,
} from './store/actions';
import { TData } from './store/types';
import { SHandleModal } from './style';

import { request } from '@api/axios';
import API_URL from '@api/url';
import ImageSkeleton from '@assets/images/image-skeleton.svg';
import HookForm from '@components/Input/HookForm';
import { Col, Row } from 'styled-bootstrap-grid';

export const generateFormData = (data: any, img: { [key in string]: File }) => {
  const form = new FormData();
  form.append('name', data?.name);
  form.append('quantity', data?.quantity);
  form.append('description', data?.description);
  form.append('price', data.price);
  form.append('currency', 'VND');
  form.append('categoryId', data?.category?.id || '1');
  form.append('categoryData', JSON.stringify(data?.categoryData || {}));

  if (data.status !== undefined) {
    form.append('status', data?.status ? 'active' : 'lock');
  }

  const image = Object.keys(img);
  if (image.length > 0 && image.length <= 6) {
    for (var i = 0; i < image.length; i++) {
      if (img[image[i]] instanceof File || img[image[i]] instanceof Blob) {
        if (i === 0) form.append(`imageMain`, img[image[i]]);
        else form.append(`imageDescription`, img[image[i]]);
      }
    }
  }
  return form;
};

export default function ModalProduct({
  open,
  setOpen,
  productData,
}: {
  open: boolean;
  setOpen: any;
  productData?: any;
}) {
  const [isShowing, toggleModal] = useState(false);

  const { handleSubmit, errors, control, register, watch, setError, setValue } =
    useForm();

  const dispatch = useDispatch();

  const [blacklist, setBlackList] = useState([]);
  const [category, setCategory] = useState([]);
  const [img, setImg] = useState<any>([]); // File | object
  // const [imgChange, setImgChange] = useState<boolean>(false);
  const [productFetch, setProductFetch] = useState<any>();

  useEffect(() => {
    request({ method: 'GET', url: API_URL.KEYWORD.BLACKLIST }).then(res => {
      setBlackList(res?.data?.data?.map((i: any) => i.name));
    });
    request({
      method: 'GET',
      url: API_URL.PRODUCTS.GET_CATEGORY,
      params: { all: true },
    }).then(res => {
      setCategory(res?.data?.data || []);
    });
  }, []);

  const check_black_list = (str: string) => {
    let check = true;
    for (let index = 0; index < blacklist.length; index++) {
      let element: string = blacklist[index];
      element = element.trim().replaceAll(' ', '');
      if (str.replaceAll(' ', '').includes(element)) {
        check = false;
        break;
      }
    }
    return check;
  };

  const onSubmit = (data: TData) => {
    const valid_name = check_black_list(data?.name);
    const valid_desc = check_black_list(data?.description);

    if (!valid_name) {
      setError('name', {
        message:
          'Tên sản phẩm chứa từ khóa cấm. Vui lòng loại bỏ các từ cấm khỏi "Tên" sản phẩm',
      });
    }

    if (!valid_desc) {
      setError('description', {
        message:
          'Mô tả sản phẩm chứa từ khóa cấm. Vui lòng loại bỏ các từ cấm khỏi "Mô tả" sản phẩm',
      });
    }

    if (valid_name && valid_desc) {
      let dataForm = generateFormData(data, img);

      if (dataForm) {
        if (productFetch?.product?.id) {
          // update
          handleUpdateProduct(dataForm, productFetch?.product?.id)
            .then(() => {
              dispatch(onRefresh());
              setOpen(false);

              Alert({
                name: 'Upload sản phẩm thành công',
                icon: 'success',
              });
            })
            .catch(HandleError);
        } else {
          if (Object.keys(img).length < 1) {
            Alert({
              name: 'Chọn từ 1 - 6 ảnh.',
              icon: 'info',
            });
            return;
          }
          // create
          handlePostProduct(dataForm)
            .then(() => {
              dispatch(onRefresh());
              setOpen(false);

              Alert({
                name: 'Upload sản phẩm thành công',
                icon: 'success',
              });
            })
            .catch(HandleError);
        }
      }
    }
  };

  useEffect(() => {
    toggleModal(open);
  }, [open]);

  const categoryInput = useMemo(() => {
    const list = watch('category')?.list;
    const result: any[] = [];
    if (list) {
      Object.keys(list).forEach((key, index) => {
        result.push(
          <Col col={6}>
            <div className="modal_input">
              <label style={{ textTransform: 'capitalize' }}>{list[key]}</label>
              <Input ref={register()} name={`categoryData.${key}`} />
            </div>
          </Col>,
        );
      });
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('category')]);

  useEffect(() => {
    if (productData) {
      handleGetOneProduct(productData.id)
        .then(res => {
          setProductFetch(res.data);
        })
        .catch(HandleError);
    }
  }, [productData]);

  const seedImg = async (imageAdd: any[]) => {
    let imgs: any = {};
    for (let i = 0; i < imageAdd.length; i++) {
      const element = imageAdd[i];
      const file = await fetch(
        `${process?.env?.REACT_APP_SERVER_URL}/${element?.path}`,
      ).then(r => r.blob());

      if (file?.type.includes('image')) {
        imgs[i] = file;
      }
    }
    setImg(imgs);
  };

  useEffect(() => {
    if (productFetch) {
      const { product } = productFetch;
      setValue('name', product?.name);
      setValue('price', product?.price || 0);
      setValue('quantity', product?.quantity || 0);
      setValue('status', product?.status === 'active' ? true : false);
      setValue('description', product?.description);
      setValue('category', {
        ...product?.category,
        label: product?.category?.name,
        value: product?.category?.id,
      });

      // seed img
      if (productFetch?.imageAdd.length > 0) {
        seedImg(productFetch?.imageAdd);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFetch]);

  useEffect(() => {
    if (productFetch) {
      const { product } = productFetch;

      if (product?.category && product?.categoryData) {
        Object.keys(product?.categoryData).forEach((key, i) => {
          setValue(`categoryData.${key}`, product?.categoryData[key]);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('category'), productFetch]);

  return (
    <Modal
      isShowing={isShowing}
      title={`${productData ? 'Cập nhật' : 'Thêm'} sản phẩm`}
      toggleModal={() => {
        setOpen(false);
      }}
      overwriteChild={{ style: { maxWidth: '991px' } }}
    >
      <SHandleModal
        encType="multipart/form-data"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* col left */}
        <div className="col_left">
          {/* upload list image, max 6 image */}
          <label>Hình ảnh sản phẩm</label>

          <div className="list-image">
            {Array(Math.min(Object.keys(img).length + 1, 6))
              .fill(0)
              .map((k, i) => {
                return (
                  <ImgUpload
                    key={i}
                    file={img[i]}
                    index={i}
                    setImg={(index: number, file: any) => {
                      let temp = { ...img };
                      temp[index] = file;
                      setImg(temp);
                    }}
                  />
                );
              })}
          </div>

          {Object.keys(img).length < 1 && (
            <Text.ErrorMessage message="Upload từ 1 - 6 ảnh cho sản phẩm." />
          )}
        </div>

        {/* col right */}
        <div className="col_right">
          <Row>
            <Col col={12}>
              <div className="modal_input">
                <label>Tên sản phẩm</label>
                <Input
                  ref={register({
                    required: VALIDATE.REQUIRE,
                    maxLength: {
                      value: 255,
                      message: 'Tối đa 255 ký tự.',
                    },
                  })}
                  name="name"
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>

            <Col col={6}>
              {' '}
              <div className="modal_input">
                <label>Giá</label>
                <div className="input_price">
                  <HookForm.InputNumberFormat
                    controller={{
                      name: 'price',
                      control: control,
                      render: data => <span>data</span>,
                      defaultValue: 0,
                      rules: {
                        min: {
                          value: 1000,
                          message: 'Nhỏ nhất 1,000 vnđ',
                        },
                        max: {
                          value: 100_000_000,
                          message: 'Tối đa 100,000,000 vnđ.',
                        },
                        required: VALIDATE.REQUIRE,
                      },
                    }}
                    numberFormat={{
                      thousandSeparator: true,
                      suffix: ' VND',
                    }}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="price"
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>
            <Col col={6}>
              <div className="modal_input">
                <label>Số lượng</label>
                <div className="input_price">
                  <HookForm.InputNumberFormat
                    controller={{
                      name: 'quantity',
                      control: control,
                      render: data => <span>data</span>,
                      defaultValue: 1,
                      rules: {
                        min: {
                          value: 1,
                          message: 'Nhỏ nhất 1',
                        },
                        required: VALIDATE.REQUIRE,
                      },
                    }}
                    numberFormat={{
                      thousandSeparator: true,
                    }}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="quantity"
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>

            {/* category start */}
            <Col col={12}>
              {' '}
              <div className="modal_input">
                <label>Ngành hàng</label>
                <div className="modal_input">
                  <HookForm.InputSelect
                    controller={{
                      control: control,
                      name: 'category',
                      render: data => <span>input select</span>,
                      rules: { required: VALIDATE.REQUIRE },
                    }}
                    select={{
                      placeholder: 'Chọn ngành hàng',
                      options: category.map((k: any, i) => ({
                        ...k,
                        label: k.name,
                        value: k.id,
                      })),
                    }}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="category"
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>

            {categoryInput}

            {/* category end */}

            <Col col={12}>
              <div className="modal_input">
                <label>Mô tả</label>
                <textarea
                  rows={4}
                  ref={register({
                    maxLength: {
                      value: 3000,
                      message: 'Tối đa 3000 ký tự.',
                    },
                    minLength: {
                      value: 6,
                      message: 'Tối thiểu 6 ký tự.',
                    },
                  })}
                  name="description"
                  style={{ resize: 'vertical' }}
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>
            <Col>
              {productData ? (
                productData?.adminApprove === 'active' ? (
                  <div className="modal_input checkbox">
                    <label style={{ minWidth: '100px' }}>Trạng thái</label>
                    <Checkbox
                      name="status"
                      ref={register()}
                      defaultChecked={
                        productData?.status === 'active' ? true : false
                      }
                    />
                  </div>
                ) : productData?.adminApprove === 'lock' ? (
                  'Phê duyệt: Đang chờ duyệt'
                ) : (
                  'Phê duyệt: Từ chối'
                )
              ) : (
                ''
              )}
            </Col>
          </Row>

          <div className="modal_buttons">
            <Button
              className="cancel"
              type="button"
              color="gray"
              onClick={() => setOpen(!isShowing)}
            >
              Hủy bỏ
            </Button>
            <Button type="submit">Lưu</Button>
          </div>
        </div>
      </SHandleModal>
    </Modal>
  );
}

// const ImgView = ({
//   file,
//   imgData,
//   isChanged,
// }: {
//   imgData: { [key in string]: any } | null;
//   file: File;
//   isChanged: boolean;
// }) => {
//   const imgPre = isChanged
//     ? file
//       ? URL.createObjectURL(file)
//       : null
//     : imgData
//     ? `${process?.env?.REACT_APP_SERVER_URL}/${imgData?.path}`
//     : null;
//   return (
//     <div className="img-upload" style={{ position: 'relative' }}>
//       <img
//         src={imgPre ? imgPre : ImageSkeleton}
//         alt="img product"
//         // style={{ width: '50px' }}
//       />
//     </div>
//   );
// };

// create: stt, setStt
// update: old data, stt, setNew

const ImgUpload = ({
  file,
  index,
  setImg,
}: {
  file: any;
  index: number;
  setImg: any;
}) => {
  let imgPre = null;

  if (file instanceof File || file instanceof Blob) {
    imgPre = URL.createObjectURL(file);
  }

  return (
    <div className="img-upload" style={{ position: 'relative' }}>
      <img src={imgPre ? imgPre : ImageSkeleton} alt="img product" />
      <input
        type="file"
        multiple={false}
        onChange={e => {
          setImg(index, e?.target?.files && e?.target?.files[0]);
        }}
      />
    </div>
  );
};
