import Button from '@components/Button';
import React, { useState } from 'react';
import { SHandle } from './style';
import { TSelect } from '@configs/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleExport,
  setPaging,
} from './store/actions';
import ShowSelect from '@components/Input/ShowSelect';
import { selectProductsStore } from './store/selecters';

import Add from '@assets/images/add-circle-icon.svg';
import Print from '@assets/images/print-icon.svg';
import ModalProduct from './ModalProduct';

export const currencies: TSelect[] = [
  { label: 'VND', value: 'VND' },
  // { label: 'USD', value: 'USD' },
];

export default function Handle() {
  const [isShowing, toggleModal] = useState(false);
  const dispatch = useDispatch();
  const { search, pagination } = useSelector(selectProductsStore);


  const onExport = async () => {
    await handleExport({
      keyword: search.keyword,
      status: search.status,
    });
  };

  return (
    <SHandle>
      <Button className="add" onClick={() => toggleModal(!isShowing)}>
        <img src={Add} alt="add" />
        Tạo mới
      </Button>
      <Button className="print" onClick={onExport}>
        <img src={Print} alt="export" />
      </Button>
      <div className="select">
        <label>Hiển thị</label>
        <ShowSelect
          defaultValue={{
            label: pagination.pageSize.toString(),
            value: pagination.pageSize.toString(),
          }}
          onChange={val => {
            let pageSize = pagination.pageSize;
            if (val && parseInt(val.value) !== pageSize) {
              pageSize = parseInt(val.value);
              dispatch(setPaging({ ...pagination, pageSize }));
            }
          }}
        />
      </div>

      {isShowing && (
        <ModalProduct open={isShowing} setOpen={toggleModal} />
      )}
    </SHandle>
  );
}
