import { request, requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import { Button, Text } from '@components/index';
import { HandleError, HandleSuccess } from '@components/HandleResponse';
import Input from '@components/Input';
import { selectAppStore } from '@containers/App/store/selecters';
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import { SShopInfo } from './style/SShopInfo';
import { publicURL } from '@helpers/index';
import SectionCollapse from '@components/SectionCollapse';

export default function ShopInfo() {
  const { user } = useSelector(selectAppStore);
  const [shop, setShop] = useState<any>();
  const [imgPreview, setImg] = useState<any>();
  const { register, handleSubmit, errors } = useForm();

  const onSub = (data: any) => {
    //console.log('data', data);
    let form = new FormData();

    form.append('name', data.name);
    form.append('url', data.url);
    form.append('description', data.description);
    form.append('mobile', data.mobile);

    if (data.avatart) form.append('image', data.avatart[0]);

    requestInterToken({
      method: 'PUT',
      url: API_URL.SHOP.PUT(shop?.id),
      data: form,
    })
      .then(HandleSuccess)
      .catch(HandleError);
  };

  useEffect(() => {
    if (user?.id)
      request({ method: 'GET', url: API_URL.SHOP.GET(user.id) })
        .then(res => {
          setShop(res.data);
        })
        .catch(HandleError);
  }, [user]);

  const onChooseImage = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImg(URL.createObjectURL(file));
    } else {
      setImg(null);
    }
  };

  return (
    <SectionCollapse title="Thông tin gian hàng" active={false}>
      <SShopInfo>
        <form onSubmit={handleSubmit(onSub)} className="form">
          <Row>
            <Col col={12}>
              <div className="form-input upload-img">
                <div className="label">Ảnh</div>
                <div className="img">
                  <img
                    src={
                      imgPreview ? imgPreview : publicURL(shop?.avatar?.path)
                    }
                    alt="avatar shop"
                  />
                  <input
                    type="file"
                    name="avatart"
                    ref={register()}
                    onChange={onChooseImage}
                  />
                </div>
              </div>
            </Col>
            <Col col={6}>
              <div className="form-input">
                <div className="label">Tên gian hàng</div>
                <div className="input">
                  <Input
                    name="name"
                    ref={register({
                      required: true,
                    })}
                    defaultValue={shop?.name ?? ''}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name={'name'}
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>
            <Col col={6}>
              <div className="form-input">
                <div className="label">Đường link</div>
                <div className="input">
                  <Input
                    name="url"
                    ref={register({
                      required: true,
                    })}
                    defaultValue={shop?.url ?? ''}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name={'url'}
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>
            <Col col={6}>
              <div className="form-input">
                <div className="label">Số điện thoại</div>
                <div className="input">
                  <Input
                    name="mobile"
                    ref={register({
                      required: true,
                    })}
                    defaultValue={shop?.mobile ?? ''}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name={'mobile'}
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>
            <Col col={6}>
              <div className="form-input">
                <div className="label">Mô tả</div>
                <div className="input">
                  <Input
                    name="description"
                    ref={register({
                      required: true,
                    })}
                    defaultValue={shop?.description ?? ''}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name={'description'}
                  render={Text.ErrorMessage}
                />
              </div>
            </Col>
            <Col col={12}>
              <Button type="submit">Cập nhật thông tin</Button>
            </Col>
          </Row>
        </form>
      </SShopInfo>
    </SectionCollapse>
  );
}
