import Button from '@components/Button';
import Input, { DatePicker, Select } from '@components/Input';
import { TSelect } from '@configs/types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { handleSearch } from './store/actions';
import {
  ButtonWrapper,
  SearchItem,
  SearchLayout,
  SearchWrapper,
} from '@components/SearchLayout';
import { selectTransactionListStore } from './store/selecters';
import { useEffect } from 'react';

const optionsPlus: TSelect[] = [
  {
    label: '--',
    value: '',
  },
  {
    label: 'Thành công',
    value: 'success',
  },
  {
    label: 'Thất bại',
    value: 'fail',
  },
  {
    label: 'Đang xử lý',
    value: 'processing',
  },
  // {
  //   label: 'Đã hoàn tiền',
  //   value: 'refund',
  // },
];
// draft, new, success, fail, confirmed, refuse
const optionsWithdraw: TSelect[] = [
  {
    label: '--',
    value: '',
  },
  {
    label: 'Đang xử lý',
    value: 'new',
  },
  {
    label: 'Chưa xác nhận OTP',
    value: 'draft',
  },
  {
    label: 'Thành công',
    value: 'success',
  },
  {
    label: 'Thất bại',
    value: 'fail',
  },
  {
    label: 'Đã xác nhận',
    value: 'confirmed',
  },
  {
    label: 'Từ chối',
    value: 'refuse',
  },
];

const option_flow_cash = [
  { label: '--', value: '' },
  { label: 'Nội địa', value: 'local' },
  { label: 'Quốc tế', value: 'global' },
];
export default function Search() {
  const { register, handleSubmit, control } = useForm({ mode: 'all' });
  const { type } = useSelector(selectTransactionListStore);
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    // console.log('type', type, data);
    let { flow_cash, status, ...data_form } = data;
    
    data_form = {
      ...data_form,
      status: data.status?.value || '',
    };

    if (type === 'withdraw')
      data_form = {
        ...data_form,
        gatewayWithdraw: data['flow_cash']?.value || '',
      };
    else {
      data_form = {
        ...data_form,
        transactionType: data['flow_cash']?.value || '',
      };
    }

    dispatch(handleSearch(data_form));
  };

  const [optionSelect, setoptionSelect] = useState(optionsWithdraw);

  useEffect(() => {
    if (type === 'withdraw') setoptionSelect(optionsWithdraw);
    else setoptionSelect(optionsPlus);
  }, [type]);

  return (
    <SearchLayout>
      <SearchWrapper>
        {/* // ??? so stupid */}
        {type === 'withdraw' ? (
          <SearchItem>
            <p className="text">Mã giao dịch</p>
            <Input name="code" ref={register} />
          </SearchItem>
        ) : (
          ''
        )}
        {type !== 'withdraw' ? (
          <SearchItem>
            <p className="text">Mã đơn hàng</p>
            <Input name="codeBill" ref={register} />
          </SearchItem>
        ) : (
          ''
        )}

        <SearchItem>
          <p>Ngày giao dịch</p>
          <Controller
            control={control}
            name="startDate"
            defaultValue={null}
            render={({ onChange }) => <DatePicker onDayChange={onChange} />}
          />
        </SearchItem>

        <SearchItem>
          <p className="text">Ngày kết thúc</p>
          <Controller
            control={control}
            name="endDate"
            defaultValue={null}
            render={({ onChange }) => <DatePicker onDayChange={onChange} />}
          />
        </SearchItem>

        <SearchItem>
          <p className="text">Trạng thái giao dịch</p>
          <Controller
            control={control}
            name="status"
            defaultValue={{ ...optionSelect[0] }}
            render={({ onChange, value }) => (
              <Select
                options={optionSelect}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </SearchItem>
        <SearchItem>
          <p className="text">Luồng tiền</p>
          <Controller
            control={control}
            name="flow_cash"
            defaultValue={{ ...option_flow_cash[0] }}
            render={({ onChange, value }) => (
              <Select
                options={option_flow_cash}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </SearchItem>
      </SearchWrapper>

      <ButtonWrapper>
        <Button type="button" onClick={handleSubmit(onSubmit)}>
          Tìm kiếm
        </Button>
      </ButtonWrapper>
    </SearchLayout>
  );
}
