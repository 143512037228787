import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import React, { useEffect, useMemo, useState } from 'react';
import Box from './Box';
import { SPaymentLinkItem } from './style';

export default function LinkStatistic() {
  const [state, setState] = useState({
    totalCreated: 0,
    totalPaid: 0,
    totalCanceled: 0,
    totalExpired: 0,
  });

  const list = useMemo(
    () => [
      {
        name: 'Đã tạo',
        color: '#244FEF',
        icon: (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="14.5"
              fill="white"
              stroke="#EDF1FF"
              strokeWidth="3"
            />
            <circle cx="16.3232" cy="16.1162" r="5.67673" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0526 10.8455C13.7885 8.10959 18.2115 8.10959 20.9474 10.8455C23.6833 13.5814 23.6833 18.0044 20.9474 20.7403C18.2115 23.4762 13.7885 23.4762 11.0526 20.7403C8.31666 18.0044 8.31666 13.5814 11.0526 10.8455ZM15.3025 19.3493C15.3025 19.7351 15.6142 20.0468 16 20.0468C16.3809 20.0418 16.6975 19.7252 16.6975 19.3493L16.6975 16.4901H19.5566C19.9425 16.4901 20.2541 16.1785 20.2541 15.7926C20.2541 15.4068 19.9425 15.0951 19.5566 15.0951L16.6975 15.0951L16.6975 12.2359C16.6975 11.8501 16.3858 11.5384 16 11.5384C15.8149 11.5382 15.6374 11.6116 15.5066 11.7425C15.3757 11.8733 15.3023 12.0509 15.3025 12.2359V15.0951H12.4434C12.2583 15.0949 12.0808 15.1683 11.9499 15.2992C11.8191 15.43 11.7457 15.6076 11.7459 15.7926C11.7459 16.1785 12.0575 16.4901 12.4434 16.4901L15.3025 16.4901V19.3493Z"
              fill="#4A70FF"
            />
          </svg>
        ),
        value: state.totalCreated,
      },
      {
        name: 'Thanh toán thành công',
        color: '#06B978',
        icon: (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="14.5"
              fill="white"
              stroke="#C8FFEB"
              strokeWidth="3"
            />
            <circle cx="16" cy="16" r="7.5" fill="#03CB83" stroke="white" />
            <path
              d="M14.6645 17.9533L13.2187 16.652C13.1408 16.5818 13.0351 16.5423 12.9249 16.5423C12.8147 16.5423 12.709 16.5818 12.6312 16.652C12.4687 16.7983 12.4687 17.0345 12.6312 17.1808L14.3728 18.7483C14.5353 18.8945 14.7978 18.8945 14.9603 18.7483L19.3687 14.7808C19.5312 14.6345 19.5312 14.3983 19.3687 14.252C19.2908 14.1818 19.1851 14.1423 19.0749 14.1423C18.9647 14.1423 18.859 14.1818 18.7812 14.252L14.6645 17.9533Z"
              fill="white"
            />
          </svg>
        ),
        value: state.totalPaid,
      },
      {
        name: 'Thất bại',
        color: '#EA001B',
        icon: (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="14.5"
              fill="white"
              stroke="#FFEBEB"
              strokeWidth="3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.99 9.37106C20.8847 9.72426 23.7365 13.1685 23.3698 17.0762C23.0031 20.9839 19.5585 23.8571 15.6638 23.5039C11.7691 23.1507 8.91733 19.7065 9.28404 15.7988C9.65074 11.891 13.0953 9.01785 16.99 9.37106ZM13.1062 18.2049C12.8056 18.4556 12.7638 18.9007 13.0127 19.2012C13.2622 19.4946 13.7129 19.5355 14.0058 19.2913L16.233 17.4338L18.0769 19.6605C18.3257 19.961 18.7694 20.0012 19.07 19.7505C19.3705 19.4999 19.4123 19.0547 19.1635 18.7542L17.3196 16.5276L19.5467 14.6701C19.8473 14.4195 19.8891 13.9743 19.6402 13.6738C19.5211 13.5296 19.3494 13.439 19.1631 13.4221C18.9768 13.4052 18.7911 13.4634 18.6471 13.5838L16.4199 15.4412L14.576 13.2146C14.4569 13.0703 14.2852 12.9798 14.0989 12.9629C13.9126 12.946 13.7269 13.0041 13.5829 13.1245C13.2824 13.3752 13.2406 13.8203 13.4894 14.1208L15.3333 16.3475L13.1062 18.2049Z"
              fill="#FF5252"
            />
          </svg>
        ),
        value: state.totalCanceled,
      },
      {
        name: 'Bị hết hạn',
        color: '#A7A6B4',
        icon: (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="14.5"
              fill="white"
              stroke="#E6ECF2"
              strokeWidth="3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.33325 15.9999C9.33325 12.3199 12.3199 9.33325 15.9999 9.33325C19.6799 9.33325 22.6666 12.3199 22.6666 15.9999C22.6666 19.6799 19.6799 22.6666 15.9999 22.6666C12.3199 22.6666 9.33325 19.6799 9.33325 15.9999ZM12.6666 15.9999C12.6666 16.3666 12.9666 16.6666 13.3333 16.6666H18.6666C19.0333 16.6666 19.3333 16.3666 19.3333 15.9999C19.3333 15.6333 19.0333 15.3333 18.6666 15.3333H13.3333C12.9666 15.3333 12.6666 15.6333 12.6666 15.9999Z"
              fill="#8F919D"
            />
          </svg>
        ),
        value: state.totalExpired,
      },
    ],
    [state],
  );

  useEffect(() => {
    requestToken({
      method: 'GET',
      url: API_URL.DASHBOARD.LINK_STATISTIC,
    }).then(res => setState(res.data));
  }, []);

  return (
    <Box title="Thống kê Đơn hàng" style={{ padding: '32px 25px 6px' }}>
      <>
        {list.map((item, index) => {
          return (
            <SPaymentLinkItem color={item.color} key={index}>
              <div className="icon">{item.icon}</div>
              <div className="name">
                <p>Đơn hàng</p>
                <p className="name__value">{item.name}</p>
              </div>
              <div className="value">{item.value}</div>
            </SPaymentLinkItem>
          );
        })}
      </>
    </Box>
  );
}
