import React, { ReactChild, ReactChildren, useEffect } from 'react';
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import theme from '@styles/theme';

interface Props {
  children: ReactChild | ReactChildren;
}
export default function HomeLayout({ children }: Props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    AOS.init();
  }, []);
  return (
    <div style={{fontFamily: theme.fonts['Roboto-Regular']}}>
      {children}
      <Footer />
    </div>
  );
}
