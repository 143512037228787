import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAppStore } from '@containers/App/store/selecters';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import useMediaQuery from '@hooks/useMediaQuery';
import { publicURL } from '@helpers/index';
import useDetectClickOutSide from '@hooks/useDetectClickOutSide';
import { mixinsScrollBarNone } from '@styles/mixins';
import theme from '@styles/theme';
// import images
import IUserHandle from '@assets/images/user-handle.svg';
import IInfor from '@assets/images/icon-infor.svg';
import IChangePassword from '@assets/images/icon-changepassword.svg';
import ILogout from '@assets/images/icon-logout.svg';

export default function DropInforUser() {
  const { user } = useSelector(selectAppStore);
  const { width } = useMediaQuery();
  const [show, setShow] = useState(false);

  const ref = useRef(null);
  const refParent = useRef(null);

  useDetectClickOutSide({
    ref: ref,
    handler: () => {
      setShow(false);
    },
    refParent: refParent,
    handlerParent: () => {
      setShow(!show);
    },
  });

  return (
    <SDropInforUser>
      <div ref={refParent} className="info-user__child user">
        {width > 991 && (
          <span className="user-name">{`${user.firstName} ${user.lastName}`}</span>
        )}

        <span className="user-avatar">
          <img
            src={
              user?.avatar?.path
                ? publicURL(user.avatar.path)
                : require('@assets/images/upload-avatar.svg')
            }
            alt="avatar"
          />
        </span>

        {width > 991 && (
          <span className="user-handle">
            <img src={IUserHandle} alt="IUserHandle" />
          </span>
        )}
      </div>

      <div ref={ref} className={`list-options ${show && 'show'}`}>
        <InforItem
          handle={() => {
            setShow(false);
          }}
          Icon={() => <img src={IInfor} alt="icon" />}
          to="/dashboard/info-user"
          name="Thông tin cá nhân"
        />
        <InforItem
          handle={() => {
            setShow(false);
          }}
          Icon={() => <img src={IChangePassword} alt="icon" />}
          to="/change-password"
          name="Đổi mật khẩu"
        />
        <InforItem
          Icon={() => <img src={ILogout} alt="icon" />}
          to="/login"
          name="Đăng xuất"
          handle={() => {
            localStorage.clear();
          }}
        />
      </div>
    </SDropInforUser>
  );
}

const SDropInforUser = styled.div`
  position: relative;
  user-select: none;

  .list-options {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0 32px;

    color: #828282;
    top: calc(100% + 15px);
    position: absolute;
    content: '';
    width: 347px;
    background: #ffffff;
    border-radius: 10px;
    right: 0;
    overflow: hidden;
    z-index: 1;
    box-shadow: 5px 10px 20px rgba(113, 119, 150, 0.1);
    ${mixinsScrollBarNone};
    max-width: 230px;
    padding: 0;
    max-height: 0px;

    &.show {
      max-height: 450px;
      overflow: unset;
      padding: 32px 0;
      transition: height 0.3s ease, padding 0s;
    }

    &::before {
      content: '';
      position: absolute;
      right: 28px;
      top: -8px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      background: white;
      z-index: 1;
      transform: rotate(45deg);
    }
  }
`;

interface TInforItem extends NavLinkProps {
  Icon: React.FunctionComponent<React.SVGProps<any>>;
  name: string;
  handle?: Function;
}

const InforItem = ({ Icon, name, handle, ...props }: TInforItem) => {
  return (
    <SInforItem
      {...props}
      onClick={() => {
        handle && handle();
      }}
    >
      <span className="icon">
        <Icon />
      </span>
      {name}
    </SInforItem>
  );
};

const SInforItem = styled(NavLink)`
  width: 100%;
  position: relative;
  padding-bottom: 12px;
  padding-top: 12px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  span.icon {
    position: absolute;
    left: 24px;
  }

  padding-left: 69px;
  padding-right: 24px;

  &:hover {
    background-color: ${theme.color.background};
  }
`;
