import React from 'react';
import { Button, Input, DatePicker } from '@components/index';
import { Controller, useForm } from 'react-hook-form';
import { changeSearch } from './store/actions';
import HookForm from '@components/Input/HookForm';
import { useDispatch } from 'react-redux';
import {
  ButtonWrapper,
  SearchItem,
  SearchLayout,
  SearchWrapper,
} from '@components/SearchLayout';

const list = [
  { name: 'Đơn hàng', key: 'url' },
  { name: 'Mã đơn hàng', key: 'codeBill' },
  // { name: 'Khách hàng', key: 'emailCustomer' },
  { name: 'Trạng thái Đơn hàng', key: 'status' },
  { name: 'Bắt đầu', key: 'startDate' },
  { name: 'Kết thúc', key: 'endDate' },
];

export enum StatusDetailPayment {
  USED = 'used',
  UNUSED = 'unused',
  DISABLE = 'disable',
  EXPIRED = 'expired',
}

const status = [
  { label: '---', value: '' },
  { label: 'Đã sử dụng', value: 'used' },
  { label: 'Chưa sửa dụng', value: 'unused' },
  { label: 'Bị vô hiệu hóa', value: 'disable' },
  { label: 'Hết hạn', value: 'expired' },
];

export default function Search() {
  const { register, handleSubmit, control } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    dispatch(changeSearch({ ...data, status: data.status.value }));
  };

  return (
    <SearchLayout onSubmit={handleSubmit(onSubmit)}>
      <SearchWrapper>
        {list.map((input, index) => {
          if (input.key === 'status') {
            return (
              <SearchItem>
                <p className="text">{input.name}</p>
                <HookForm.InputSelect
                  controller={{
                    control: control,
                    name: input.key,
                    render: data => <span>input select</span>,
                    defaultValue: '',
                  }}
                  select={{
                    placeholder: 'Nhập...',
                    options: status,
                  }}
                />
              </SearchItem>
            );
          }
          if (input.key === 'endDate' || input.key === 'startDate') {
            return (
              <SearchItem>
                <p className="text">{input.name}</p>
                <Controller
                  control={control}
                  name={input.key}
                  render={props => (
                    <DatePicker
                      {...props}
                      onDayChange={props.onChange}
                      left={input.key === 'endDate'}
                    />
                  )}
                />
              </SearchItem>
            );
          } else
            return (
              <SearchItem>
                <p className="text">{input.name}</p>
                <Input ref={register()} name={input.key} />
              </SearchItem>
            );
        })}
      </SearchWrapper>

      <ButtonWrapper>
        <Button type="submit">Tìm kiếm</Button>
      </ButtonWrapper>
    </SearchLayout>
  );
}
