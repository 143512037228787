import theme from '@styles/theme';
import React, { ReactChild, ReactChildren } from 'react';
import styled from 'styled-components';
import { mixinAbsolute, mixinsFlexCenter } from '@styles/mixins';

//icon
import IBack from '@assets/images/icon-back-section.svg';
import { useHistory } from 'react-router';

interface Props {
  title: string;
  children: ReactChild | ReactChildren;
}
export default function Section({ children, title }: Props) {
  const history = useHistory();
  return (
    <StyleSection>
      <h2 className="title">
        {title}
        <img
          src={IBack}
          alt="back"
          className="icon-back"
          onClick={() => history.goBack()}
        />
      </h2>
      <div className="content-section">{children}</div>
    </StyleSection>
  );
}

export const StyleSection = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(141, 171, 255, 0.2);
  border-radius: 12px;

  h2.title {
    font-family: ${theme.fonts['K2D-Light']};
    /* font-weight: 500; */
    font-size: 24px;
    line-height: 28px;
    color: ${theme.color.text_chinh};
    mix-blend-mode: normal;
    position: relative;
    padding-left: 72px;
    margin: 0;
    ${mixinsFlexCenter};
    justify-content: start;
    min-height: 80px;
    border-bottom: 1px solid #e3e8f0;

    .icon-back {
      ${mixinAbsolute};
      top: 34px;
      left: 36px;
      cursor: pointer;
    }
  }

  .content-section {
    padding: 52px 34px 81px;
  }

  @media screen and (max-width: 991px) {
    .content-section {
      padding: 40px 20px;
    }
    h2.title {
      padding-left: 56px;
      .icon-back {
        left: 20px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .content-section {
      padding: 20px 15px;
    }
    h2.title {
      padding-left: 44px;
      .icon-back {
        left: 15px;
      }
    }
  }
`;
