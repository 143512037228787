import React, { useState } from 'react';
import Table from '@components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { selectPaymentLinksStore } from './store/selecters';
import { Radio } from '@components/Input';
import * as format from '@helpers/format';
import Pagination from '@components/Paginations';
import { changePage, reloadData } from './store/actions';
import DetailPayment from './DetailPayment';
import Modal from '@components/Modal';
import ModalChangeStatus from './ModalChangeStatus';
import ModalRefund from './ModalRefund';
import MoreOptions from '@components/MoreOptions';
import styled from 'styled-components';
import ModalSupport from './ModalSupport';

export const CTHead = [
  '',
  'Mã đơn hàng',
  'Kênh thanh toán',
  'Số tiền',
  'Ngày tạo',
  'Trạng thái thanh toán',
  'Trạng thái Đơn hàng',
  'Yêu cầu xử lý',
  'Đơn hàng',
];

export default function DataTable({ loading }: { loading: boolean }) {
  const { pagination, data, total } = useSelector(selectPaymentLinksStore);
  const dispatch = useDispatch();

  const [showChangeStatus, setShowChangeStatus] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showRefund, setShowRefund] = useState<boolean>(false);
  const [showSupport, setshowSupport] = useState<boolean>(false);

  const [picker, setPicker] = useState<any>(null);

  return (
    <>
      <Table
        style={{ border: true }}
        isLoading={loading}
        thead={CTHead.map((t, i) => (
          <th style={{ minWidth: i !== 0 ? '120px' : '10px' }} key={i}>
            {t}
          </th>
        ))}
        tbody={data.map((p, index) => {
          return (
            <tr
              key={index}
              style={{
                color: p.paid === 0 || p.paid === null ? '#828282' : '#3A3B42',
              }}
            >
              <td>
                <Radio
                  width={40 / 3}
                  height={40 / 3}
                  name="payment-pick-item"
                  checked={picker === p ? true : false}
                  onClick={e => {
                    e.preventDefault();
                    setPicker(p);
                    setShowDetails(!showDetails);
                  }}
                />
              </td>
              <td
                onClick={e => {
                  e.preventDefault();
                  setPicker(p);
                  setShowDetails(!showDetails);
                }}
                style={{ cursor: 'pointer' }}
              >
                {p.codeBill}
              </td>
              <td>{p.transactionsGateway}</td>
              <td>{format.money(p.money, undefined, true)}</td>
              <td>{new Date(p.createdAt).toLocaleDateString('vi-VN')}</td>
              <td>
                {p.paid === null
                  ? 'Đang chờ xử lý'
                  : p.paid === 0
                  ? 'Thất bại'
                  : 'Thành công'}
              </td>
              <td>{format.convertStatus(p.statusDetail)}</td>
              <td>
                {p?.supportId ? 'Yêu cầu hỗ trợ \n' : ''}
                {p?.refundId ? 'Hoàn tiền' : ''}
              </td>
              <td>{p.url}</td>
              <td>
                {p.paid === null ? (
                  ''
                ) : p.paid !== 0 ? (
                  <SMoreAction>
                    <MoreOptions
                      options={[
                        {
                          name: 'Hoàn tiền ',
                          callback: () => {
                            setPicker(p);
                            setShowRefund(!showRefund);
                          },
                        },
                        {
                          name: 'Hỗ trợ',
                          callback: () => {
                            setPicker(p);
                            setshowSupport(!showSupport);
                          },
                        },
                      ]}
                      style={{
                        position: 'left',
                      }}
                    />
                  </SMoreAction>
                ) : (
                  ''
                )}
              </td>
            </tr>
          );
        })}
      />

      {(total || 0) / pagination.pageSize >= 1 && (
        <Pagination
          onChange={(pageNumber: number, pageSize: number) => {
            dispatch(changePage({ pageNumber, pageSize }));
          }}
          pageSize={pagination.pageSize}
          current={pagination.pageNumber}
          total={total}
        />
      )}

      {/* modal */}
      {picker && (
        <>
          {/* Chi tiết Đơn hàng */}
          <Modal
            title="Chi tiết Đơn hàng"
            isShowing={showDetails}
            toggleModal={() => setShowDetails(!showDetails)}
            overwriteChild={{ style: { maxWidth: '720px' } }}
          >
            <DetailPayment
              data={picker}
              changeStatus={() => {
                // setPicker(p);
                setShowChangeStatus(!showChangeStatus);
              }}
            />
          </Modal>

          {/* Thay đổi trạng thái */}
          <Modal
            title="Thay đổi trạng thái"
            isShowing={showChangeStatus}
            toggleModal={() => setShowChangeStatus(!showChangeStatus)}
            overwriteChild={{ style: { maxWidth: '650px' } }}
          >
            <ModalChangeStatus
              data={picker}
              toggle={() => setShowChangeStatus(!showChangeStatus)}
              callback={() => {
                dispatch(reloadData());
                setShowDetails(!showDetails);
              }}
            />
          </Modal>

          {/* Hoàn tiền */}
          <Modal
            title="Hoàn tiền"
            isShowing={showRefund}
            toggleModal={() => setShowRefund(!showRefund)}
            overwriteChild={{ style: { maxWidth: '650px' } }}
          >
            <ModalRefund
              data={picker}
              setShowRefund={setShowRefund}
              callback={() => {
                dispatch(reloadData());
              }}
            />
          </Modal>

          {/* Yêu cầu hỗ trợ */}
          <Modal
            title="Yêu cầu hỗ trợ"
            isShowing={showSupport}
            toggleModal={() => setshowSupport(!showSupport)}
            overwriteChild={{ style: { maxWidth: '650px' } }}
          >
            <ModalSupport
              data={picker}
              setOpen={setshowSupport}
              callback={() => {
                dispatch(reloadData());
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
}

const SMoreAction = styled.span`
  display: flex;
`;
