import styled from 'styled-components';
import instructionBg from '@assets/images/Home/instruction-bg.png';
import theme from '@styles/theme';

const WrapAuthenticationDetail = styled.div`
padding: 156px 0 162px;
background-image: url(${instructionBg});
background-position: top;
background-size: cover;
background-repeat: no-repeat;
p {
  margin: unset;
}

.wrap_inside {
  padding: 56px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.8) 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 20px;

  .title {
    font-family: ${theme.fonts['Roboto-Bold']};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    text-transform: uppercase;
    color: #110E22;
    text-align: center;
    margin-bottom:56px;
  }

  .name {
    font-family: ${theme.fonts['Roboto-Bold']};
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #37474F;
    margin-bottom: 20px;
  }

  .steps {
    .step_item {
      margin-bottom: 56px;
      .step_item_detail {
      margin-bottom: 16px;
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #535A71;
          span {
            background: #1FD392;
            padding: 5px 10px;
            border-radius: 50%;
            margin-right: 9px;
            color: white;
          }
        }

        .des {
          margin-top: 16px;
          padding-left: 34px;
          .note {
            font-family: ${theme.fonts['Roboto-Regular']};
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #37474F;
            margin-bottom: 20px;
          }

          .note_detail {
            p {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #8D95B0;
            }
          }

          img {
            margin-bottom: 44px;
          }
        }
      }


      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .wrap_inside {
    padding: 5px;
  }
}`
export default WrapAuthenticationDetail;
