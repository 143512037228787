import { NewsHeader } from '@components/Layout/NewsLayout/Header';
import React, { useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import logo from '@assets/images/logo.svg';
import Image from '@components/Image';
import { useDispatch, useSelector } from 'react-redux';
import { selectStorePageStore } from '@containers/StorePage/store/selecters';
import { request } from '@api/axios';
import API_URL from '@api/url';
import { setBuyer } from '@containers/StorePage/store/actions';

interface LinkProps {
  name: string;
  path: string;
  exact: boolean;
  innerPage?: boolean;
}

const links = [
  {
    name: 'Trang chủ',
    path: '/',
    exact: true,
  },
  {
    name: 'Cửa hàng',
    path: '/store',
    exact: true,
  },
  // {
  //   name: 'Giới thiệu',
  //   path: '/#introduce',
  //   // exact: true,
  //   innerPage: true,
  // },
  {
    name: 'Hướng dẫn sử dụng',
    path: '/instructions',
    exact: true,
  },
  {
    name: 'Kênh người bán',
    path: '/login',
    exact: true,
  },
];

const loginRoutes = ['/login', '/signup', '/forgot-password', '/new-password'];

export default function Header() {
  const his = useHistory();
  const location = useLocation();

  const handleScroll = () => {
    let headerDOM = document.getElementById('header') as HTMLElement;
    let offsetY = window.pageYOffset;
    if (offsetY >= 40) {
      headerDOM.classList.add('scrolled');
    } else {
      headerDOM.classList.remove('scrolled');
    }
  };

  const handleCloseMenu = () => {
    let menuDOM = document.getElementsByClassName('links')[0] as HTMLElement;
    let overlayDOM = document.getElementById('overlay') as HTMLElement;

    menuDOM!.style.left = '-200px';
    overlayDOM!.style.display = 'none';
    document.body.style.height = 'auto';
    document.body.style.overflow = 'visible';
  };

  const handleOpenMenu = () => {
    let menuDOM = document.getElementsByClassName('links')[0] as HTMLElement;
    let overlayDOM = document.getElementById('overlay') as HTMLElement;

    menuDOM!.style.left = '0px';
    overlayDOM!.style.display = 'block';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderLink = (
    { name, path, exact, innerPage }: LinkProps,
    index: number,
  ) => {
    if (innerPage)
      return (
        <a
          onClick={handleCloseMenu}
          className="link"
          href={`${path}`}
          // exact={exact}
          key={'link' + index}
        >
          {name}
        </a>
      );
    return (
      <NavLink
        onClick={handleCloseMenu}
        className="link"
        to={path}
        exact={exact}
        key={'link' + index}
      >
        {name}
      </NavLink>
    );
  };

  const onToggleMenu = () => {
    let menuDOM = document.getElementsByClassName('links')[0] as HTMLElement;
    if (menuDOM.style.left === '0px') {
      handleCloseMenu();
    } else {
      handleOpenMenu();
    }
  };

  const isLoginRoute = loginRoutes.find(route => {
    return location.pathname.includes(route);
  });

  const { buyer } = useSelector(selectStorePageStore);
  // console.log('buyer::', buyer);
  const dispatch = useDispatch();
  // get buyer profile
  useEffect(() => {
    const token = localStorage.getItem('buyer-token');
    if (token) {
      request({
        method: 'GET',
        url: API_URL.BUYER.PROFILE,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(res => {
        dispatch(setBuyer(res?.data || {}));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsHeader id="header" isLoginRoute={isLoginRoute ? true : false}>
      <div className="header-wrapper">
        <NavLink to="/" className="logo">
          <div className="logo-container">
            <Image src={logo} alt="" />
          </div>
        </NavLink>
        <div className="links">
          <button id="close-menu" onClick={handleCloseMenu}>
            <FontAwesomeIcon icon={faWindowClose} />
          </button>
          {links.map((link: any, index: number) => {
            return renderLink(link, index);
          })}

          {buyer?.id ? (
            <div className="buyer-info">
              {buyer?.phone}
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <g clipPath="url(#clip0_38_662)">
                    <path
                      fill="#fff"
                      d="M2 5v2h20V5H2zm0 6v2h20v-2H2zm0 6v2h20v-2H2z"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_38_662">
                      <path fill="#fff" d="M0 0H24V24H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div className="menu-drop">
                <div
                  className="drop-item"
                  onClick={e => {
                    e.preventDefault();
                    his.push('/store/order');
                  }}
                >
                  <div className="drop-item_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <g clipPath="url(#clip0_38_705)">
                        <path
                          fill="#8D95B0"
                          d="M15.833 1.667a.834.834 0 01-1.666 0H12.5a.834.834 0 01-1.667 0H9.167a.834.834 0 01-1.667 0H5.833a.834.834 0 01-1.666 0h-.834A.834.834 0 002.5 2.5v14.167c0 .92.746 1.666 1.667 1.666h11.666c.921 0 1.667-.745 1.667-1.666V2.5a.834.834 0 00-.833-.833h-.834zm-5 13.333h-5a.834.834 0 010-1.667h5a.834.834 0 010 1.667zm0-3.333h-5a.834.834 0 010-1.667h5a.834.834 0 010 1.667zM14.583 15h-.833a.417.417 0 01-.417-.417v-.833c0-.23.187-.417.417-.417h.833c.23 0 .417.187.417.417v.833c0 .23-.187.417-.417.417zm0-3.333h-.833a.417.417 0 01-.417-.417v-.833c0-.23.187-.417.417-.417h.833c.23 0 .417.187.417.417v.833c0 .23-.187.417-.417.417zM14.167 7.5H5.833a.834.834 0 010-1.667h8.334a.834.834 0 010 1.667z"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_38_705">
                          <path fill="#fff" d="M0 0H20V20H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="drop-item_text">Quản lý đơn hàng</div>
                </div>
                <div
                  className="drop-item"
                  onClick={e => {
                    e.preventDefault();
                    localStorage.clear();
                    his.push('/');
                    window.location.reload();
                  }}
                >
                  <div className="drop-item_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#8D95B0"
                        d="M12.875 15.089a.845.845 0 01-.582.235.84.84 0 01-.839-.839v-1.588h-1.057v3.187c0 .38-.251.71-.62.811L3.277 18.64a.884.884 0 01-.212.028.83.83 0 01-.839-.839V2.171a.83.83 0 01.324-.666.846.846 0 01.727-.145l6.498 1.745c.37.1.621.43.621.81v3.188h1.057V5.515a.84.84 0 01.515-.772.825.825 0 01.906.168l4.647 4.485a.837.837 0 01.251.604c0 .23-.09.447-.251.604l-4.647 4.485zM8.72 4.559L3.905 3.267v13.466l4.815-1.292v-2.544H7.08a.838.838 0 01-.839-.839V7.942c0-.464.375-.839.839-.839H8.72V4.56z"
                      ></path>
                    </svg>
                  </div>
                  <div className="drop-item_text">Đăng xuất</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="group-buttons">
              <NavLink
                onClick={handleCloseMenu}
                className="button_1"
                exact={true}
                to="/store/auth"
              >
                Đăng nhập
              </NavLink>
            </div>
          )}
        </div>
        <button id="toggle-menu" onClick={onToggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
    </NewsHeader>
  );
}
