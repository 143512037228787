import Button from '@components/Button';
import React from 'react';
import { SHandle } from './style';
import Print from '@assets/images/print-icon.svg';
import ShowSelect from '@components/Input/ShowSelect';
import { useDispatch, useSelector } from 'react-redux';
import { selectTransactionListStore } from './store/selecters';
import { setPaging } from './store/actions';
import { requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import {
  clearParams,
  convertStatus,
  convertStatusWithDraw,
} from '@helpers/format';
import { HandleError } from '@components/HandleResponse';
import exportExcel from '@helpers/exportExcel';

export default function Handle() {
  const dispatch = useDispatch();
  const { pagination, search, type } = useSelector(selectTransactionListStore);

  const onExport = async () => {
    // console.log('type', type);
    try {
      let dataExcel: any = [],
        name;
      if (type === 'plus') {
        name = 'Danh sách cộng tiền';
        dataExcel = (
          await requestInterToken({
            method: 'GET',
            url: API_URL.TRANSACTION.GET,
            params: clearParams({ ...search, listType: 'excel' }),
          })
        ).data.map((d: any, index: number) => ({
          STT: index + 1,
          'Mã đơn hàng': d?.paymentLink?.codeBill,
          'Số tiền cộng vào ví': d?.plusMoney || 0,
          'Số tiền thanh toán': d?.money,
          'Phí giao dịch': (d?.fee * d?.money) / 100 + d?.fixedFee || 0,
          'Thời gian yêu cầu': d?.createdAt,
          'Trạng thái': convertStatus(d?.status),
        }));
      } else {
        name = 'Danh sách rút tiền';
        dataExcel = (
          await requestInterToken({
            method: 'GET',
            url: API_URL.WITHDRAW.GET,
            params: clearParams({ ...search, listType: 'excel' }),
          })
        ).data.map((d: any, index: number) => ({
          STT: index + 1,
          'Mã giao dịch': d?.code,
          'Số tiền rút': d?.money,
          'Phí giao dịch': Number(d?.minusMoney) - Number(d?.money) || 0,
          'Thời gian yêu cầu': d?.dateRequest,
          'Trạng thái': convertStatusWithDraw(d?.status),
        }));
      }

      exportExcel(dataExcel, name);
    } catch (error) {
      HandleError(error);
    }
  };

  return (
    <SHandle>
      <Button className="print" onClick={onExport}>
        <img src={Print} alt="export" />
      </Button>
      <div className="select">
        <label>Hiển thị</label>
        <ShowSelect
          defaultValue={{
            label: pagination.pageSize.toString(),
            value: pagination.pageSize.toString(),
          }}
          onChange={val => {
            let pageSize = pagination.pageSize;
            if (val && parseInt(val.value) !== pageSize) {
              pageSize = parseInt(val.value);
              dispatch(setPaging({ ...pagination, pageSize }));
            }
          }}
        />
      </div>
    </SHandle>
  );
}
