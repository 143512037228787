const theme = {
  fonts: {
    // 'Roboto-BlackItalic': 'Roboto-BlackItalic',
    'Roboto-Black': 'Roboto-Black',
    // 'Roboto-BoldItalic': 'Roboto-BoldItalic',
    'Roboto-Bold': 'Roboto-Bold',
    // 'Roboto-Italic': 'Roboto-Italic',
    // 'Roboto-LightItalic': 'Roboto-LightItalic',
    'Roboto-Light': 'Roboto-Light',
    // 'Roboto-MediumItalic': 'Roboto-MediumItalic',
    'Roboto-Medium': 'Roboto-Medium',
    'Roboto-Regular': 'Roboto-Regular',
    // 'Roboto-ThinItalic': 'Roboto-ThinItalic',
    // 'Roboto-Thin': 'Roboto-Thin',
    // k2d
    'K2D-BoldItalic': 'K2D-BoldItalic',
    'K2D-Bold': 'K2D-Bold',
    'K2D-ExtraBoldItalic': 'K2D-ExtraBoldItalic',
    'K2D-ExtraBold': 'K2D-ExtraBold',
    'K2D-ExtraLightItalic': 'K2D-ExtraLightItalic',
    'K2D-ExtraLight': 'K2D-ExtraLight',
    'K2D-Italic': 'K2D-Italic',
    'K2D-LightItalic': 'K2D-LightItalic',
    'K2D-Light': 'K2D-Light',
    'K2D-MediumItalic': 'K2D-MediumItalic',
    'K2D-Medium': 'K2D-Medium',
    'K2D-Regular': 'K2D-Regular',
    'K2D-SemiBoldItalic': 'K2D-SemiBoldItalic',
    'K2D-SemiBold': 'K2D-SemiBold',
    'K2D-ThinItalic': 'K2D-ThinItalic',
    'K2D-Thin': 'K2D-Thin',
    // ubuntu
    'UTM Avo': 'UTM Avo',
    'Ubuntu-BoldItalic': 'Ubuntu-BoldItalic',
    'Ubuntu-Bold': 'Ubuntu-Bold',
    'Ubuntu-Italic': 'Ubuntu-Italic',
    'Ubuntu-LightItalic': 'Ubuntu-LightItalic',
    'Ubuntu-Light': 'Ubuntu-Light',
    'Ubuntu-MediumItalic': 'Ubuntu-MediumItalic',
    'Ubuntu-Medium': 'Ubuntu-Medium',
    'Ubuntu-Regular': 'Ubuntu-Regular',
  },
  color: {
    body: '#6E7B97',
    text: '#5F6169',
    background: '#F0F4FB',
    white: '#FFFFFF',
    green: '#03CB83',
    gray: '#dadada',
    red: '#EA001B',
    // from design
    gray_2: '#4F4F4F',
    gray_bg: 'rgba(246, 248, 255, 0.8)',
    gray_border: '#E2E5EF',
    line: '#E3E8F0',
    icon: '#8F919D',
    icon_2: '#93A9C7',
    icon_2_2: '#B9C0CE',
    text_chinh: '#3A3B42',
    text_phu: '#5F6169',
    text_phu_2: '#A7A6B4',
    logo: '#03CB83',
    khung: '#E3E8F0',
    link: '#4A70FF',
    linear_yellow: 'linear-gradient(180deg, #F5C51B 0%, #FF793F 100%)',
    linear_purple: 'inear-gradient(180deg, #4AB5FF 0%, #4A70FF 100%)',
    linear_blue: 'linear-gradient(180deg, #5DE6F9 0%, #37B9F2 100%)',
    linear_green: 'linear-gradient(180deg, #59E26A 0%, #4ED1A7 100%)',
    // linear_  : "xxxxxx",
  },
};

export type TypeTheme = typeof theme;
export default theme;
