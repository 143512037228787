import React, { useState } from 'react';
import { convertStatus, money } from '@helpers/format';
import { Radio } from '@components/Input';
import ModalDetailz from './ModalDetailz';

export default function DataTableRow({ product }: { product: any }) {
  const [isOpen, setIsOpen] = useState(false);
  let fee = Number(product?.transactionFee) * Number(product?.money) / 100
  return (
    <tr key={product.id}  >
      <td><Radio name="order" width={12} height={12} /></td>
      <td style={{ cursor: "pointer" }} onClick={() => {
        setIsOpen(true);
      }}>
        {product?.codeBill}
      </td>
      <td title={product?.createdAt} >
        {new Date(product?.createdAt).toLocaleDateString('vi-VN')}
      </td>
      {/* <td>
        {product?.nameProduct}
      </td>
      <td>
        {product?.quantity}
      </td>
      <td>
        {money(product?.priceProduct, undefined, true)}
      </td> */}
       {/* <td>
        {money(Number(product?.priceProduct) * Number(product?.quantity), undefined, true)}
      </td>  */}
      <td>
        {money(product?.moneyDiscount, undefined, true)}
      </td>
      <td>
        {money(fee, undefined, true)}
      </td>
      <td>
        {money(product?.transactionsMoney, undefined, true)}
      </td>
      <td>
        {product?.transactionsStatus == null ? "Chưa thanh toán" : convertStatus(product?.transactionsStatus)}
      </td>

      {isOpen && <ModalDetailz
        data={product}
        modalProps={{
          isShowing: isOpen,
          toggleModal: () => { setIsOpen(!isOpen) },
          title: "Chi tiết Đơn hàng"
        }}
      />}
    </tr>
  );
}
