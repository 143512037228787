import Button from '@components/Button';
import React from 'react';
import { useHistory } from 'react-router';
import { SHandle } from './style';
import ShowSelect from '@components/Input/ShowSelect';
import { selectPaymentLinksStore } from './store/selecters';
import { useDispatch, useSelector } from 'react-redux';
import { requestInterToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError } from '@components/HandleResponse';
import { clearParams, convertStatus } from '@helpers/format';
import { changePage } from './store/actions';
import IPrint from '@assets/images/print-icon.svg';
import Add from '@assets/images/add-circle-icon.svg';
import exportExcel from '@helpers/exportExcel';

export const mapDataExcel = (data: any[]) => {
  return data.map((item, index) => ({
    STT: index + 1,
    'Mã đơn hàng': item?.codeBill,
    'Kênh thanh toán': item?.transactionsGateway,
    'Số tiền': item?.money,
    'Ngày tạo': item?.createdAt,
    'Trạng thái thanh toán':
      item.paid === null
        ? 'Đang chờ xử lý'
        : item.paid === 0
        ? 'Thất bại'
        : 'Thành công',
    'Trạng thái Đơn hàng': convertStatus(item?.statusDetail),
    // 'Yêu cầu xử lý': item?.codeBill,
    'Đơn hàng': item?.url,
  }));
};

export default function Handle() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pagination, search } = useSelector(selectPaymentLinksStore);

  return (
    <SHandle>
      <Button
        onClick={() => {
          history.push('/dashboard/create-payment-links');
        }}
        className="add-new"
      >
        <img src={Add} alt="add" /> Tạo mới
      </Button>

      <Button
        className="print"
        onClick={() => {
          requestInterToken({
            method: 'GET',
            url: API_URL.PAYMENTLINK.GET,
            params: clearParams({ ...search, listType: 'excel' }),
          })
            .then(res => {
              // FileSaver.saveAs(res.data, 'payment-links.xlsx');
              exportExcel(mapDataExcel(res.data), 'Danh sách giao dịch');
            })
            .catch(HandleError);
        }}
      >
        <img src={IPrint} alt="export data" />
      </Button>

      <div className="select">
        <label>Hiển thị</label>
        <ShowSelect
          defaultValue={{
            label: pagination.pageSize.toString(),
            value: pagination.pageSize.toString(),
          }}
          onChange={val => {
            let pageSize = pagination.pageSize;
            if (val && parseInt(val.value) !== pageSize) {
              pageSize = parseInt(val.value);
              dispatch(changePage({ pageNumber: 1, pageSize }));
            }
          }}
        />
      </div>
    </SHandle>
  );
}
