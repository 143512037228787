import { mixinsScrollBarHeight } from '@styles/mixins';
/*
 *
 * Dashboard style css file
 * make by phamthainb
 */

import styled from 'styled-components';
import theme from '@styles/theme';
import { mixinsFlexCenter } from '@styles/mixins';
import { SBoxChart } from '../Chart/BoxChart';

export const SAnalyticsItem = styled.div<{ bgr: string; border: string }>`
  background: rgba(255, 255, 255, 0.75);
  border: 1.5px solid #ffffff;
  backdrop-filter: blur(25px);
  border-radius: 12px;
  padding: 16px;
  position: relative;
  transition: all 0.25s ease-in;
  background-clip: padding-box;
  box-sizing: border-box;

  height: 140px;
  width: 100%;
  //max-width: 256px;

  &:hover {
    z-index: 1;
    transform: translateY(-10px);
    box-shadow: ${props =>
      `0px 0px 0px 1.5px ${props.border}, 0px 10px 20px rgb(40 194 244 / 12%)`};
  }

  .icon {
    ${mixinsFlexCenter};
    position: absolute;
    width: 44px;
    height: 44px;
    background: #ffffff;
    box-shadow: 0px 8px 13px rgba(113, 119, 150, 0.16);
    border-radius: 12px;
  }

  .text {
    padding-left: calc(72px - 16px);
    font-size: 14px;
    line-height: 16px;
    color: ${theme.color.text_phu};
  }

  .value {
    font-size: 28px;
    line-height: 37px;
    color: ${theme.color.text_chinh};
    position: absolute;
    bottom: -8px;
    font-family: ${theme.fonts['K2D-Medium']};
  }
`;

export const SPaymentLinkItem = styled.div<{ color: string }>`
  position: relative;
  padding-left: 48px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e8f0;

  padding-top: 12px;
  padding-bottom: 12px;

  &:nth-last-child(1) {
    border-bottom: unset;
  }

  /* &:first-child {
    margin-top: 10px;
  } */

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    ${mixinsFlexCenter};
  }

  .name {
    p {
      margin: 4px 0;
      font-size: 16px;
      line-height: 19px;
      text-align: left;
    }

    &__value {
      color: ${props => props.color};
      font-family: ${theme.fonts['K2D-Regular']};
      font-size: 12px;
      line-height: 14px;
    }
  }

  .value {
    max-width: 30%;
    width: 100%;
    color: ${theme.color.green};
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    padding-top: 6px;

    position: absolute;
    right: 0;
    top: 8px;
  }

  @media (max-width: 1199px) {
    padding: 8px 8px 8px 48px;
  }
`;

export const SJoinNow = styled.div`
  position: relative;
  ${mixinsFlexCenter};
  flex-direction: column;
  padding: 0;

  svg,
  img {
    margin-top: -110px;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    color: #a7a6b4;
    /* margin: 70px 0; */
    text-align: center;
  }

  button {
    margin-bottom: -50px;
    width: 80%;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;

    max-width: 220px;
    height: 56px;
    border-radius: 8px;
  }
`;

export const StyleModal = styled.div`
  .link {
    background: #e4effa;
    border-radius: 2px;
    text-align: center;
    padding: 13px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #4a70ff;
    margin-bottom: 44px;
    word-break: break-word;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button_share {
      margin-right: 16px;
    }

    button {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
  }

  .content-tab {
    padding: 24px;
    transition: all 0.3s ease;
  }

  .qrcode {
    canvas {
      margin: 0 auto;
      display: block;
      padding: 24px 0 12px;
      max-width: 100%;
    }
  }
`;

const WrapDashboard = styled.div`
  margin-top: -12px;

  ul.recharts-default-legend {
    overflow: auto;
    display: flex;
    padding: 8px;
    cursor: all-scroll;
    user-select: none;
    ${mixinsScrollBarHeight};

    li.recharts-legend-item {
      min-width: 100px;
    }
  }

  #visa {
    position: relative;
    color: ${theme.color.white};
    width: 100%;
    /* max-width: 332px; */
    max-height: 219px;
    grid-area: visa;

    svg,
    img {
      width: 100%;
      height: 100%;
    }

    .text {
      position: absolute;
      top: 14px;
      left: 27px;
      font-family: ${theme.fonts['K2D-Regular']};
      font-size: 18px;
      line-height: 21px;
    }

    .monney {
      position: absolute;
      top: 70px;
      left: 30px;
      font-family: ${theme.fonts['UTM Avo']};
      font-weight: bold;
      font-size: 32px;
      line-height: 47px;
      letter-spacing: 0.04em;
    }

    .code {
      position: absolute;
      top: 160px;
      left: 30px;
      font-family: ${theme.fonts['K2D-Regular']};
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.12em;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 332px;
    gap: 12px 32px;
    grid-template-areas:
      'top top top right'
      'chart chart chart right'
      'chart chart chart right';
  }

  #dashboard-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 56px auto;
    gap: 12px 12px;
    grid-template-areas:
      'value value value '
      'value value value '
      ;
    grid-area: top;
  }

  #dashboard-top__title {
    grid-area: time_date;
    display: flex;
    padding: 22px 0 22px 24px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;

    .celendar {
      border-radius: 50%;
      width: 56px;
      height: 56px;
      background: #d3eeff;
      position: relative;
      margin-right: 20px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .time {
      .today {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #a7a6b4;
      }
      .date-time {
        margin: 0;
        color: ${theme.color.text_phu_2};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #4f4f4f;
      }
    }
  }

  #dashboard-top__link {
    grid-area: link;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    padding: 6px 24px;

    .link_icon_text {
      display: flex;
      align-items: center;

      .link_icon {
        background: #ffffff;
        border: 2px solid #edf1ff;
        box-sizing: border-box;
        padding: 8px 10px;
        border-radius: 50%;
        margin-right: 20px;
      }

      .link_text {
        font-size: 16px;
        line-height: 19px;
        color: #3a3b42;
      }
    }
  }

  #dashboard-top__value {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 144px;
    gap: 32px;
    grid-template-areas: '. . .';
    grid-area: value;
    //max-width: 892px;
    padding-right: 22px;
    //margin: 0 auto;
    align-items: flex-start;

    .value {
      span {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        text-decoration-line: underline;
        color: #828282;
      }
    }
  }

  #chart {
    grid-area: chart;
    //margin-top: -45px;

    .charts-item {
      height: 300px;
    }
  }

  #right {
    grid-area: right;
    /* margin-top: -25px; */
  }

  @media (max-width: 1399px) {
    #dashboard-top__value {
      gap: 24px;
    }
  }

  @media (max-width: 1199px) {
    #dashboard-top {
      display: block;
      #dashboard-top__value {
        margin-bottom: 20px;
      }

      #dashboard-top__title {
        margin-bottom: 20px;
      }

      #dashboard-top__link {
        margin-bottom: 20px;
      }
    }

    #visa {
      margin: 0;
    }

    .grid-container {
      grid-template-areas:
        'top top top top'
        'right right right right'
        'chart chart chart chart';

      gap: 24px;

      #chart {
        ${SBoxChart}:nth-child(1) {
          margin-bottom: 24px;
        }
      }
    }

    #dashboard-top__value {
      padding-top: 0px;
      max-width: unset;
      align-items: center;

      ${SAnalyticsItem} {
        max-width: unset;
      }
    }
  }

  @media (max-width: 767px) {
    .grid-container {
      grid-template-columns: unset;
    }

    #dashboard-top {
      grid-template-areas: 'title' 'visa' 'value';
      grid-template-columns: repeat(1, 1fr);
      gap: unset !important;
      padding-top: 24px;
      #dashboard-top__title {
        .title-page {
          padding-top: 0;
        }
        .text {
          margin-bottom: 0;
        }
        .date-time {
          margin-top: 0;
        }
      }

      #dashboard-top__value {
        width: 100%;
        padding: 0 24px;
        gap: unset !important;
        margin: 36px -12px 24px;
        /* iphone 6 */
        ${SAnalyticsItem} {
          margin: 12px;
          transform: unset !important;
          box-shadow: unset !important;
        }
      }
    }
  }

  @media (max-width: 666px) {
    #dashboard-top__value {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      padding: 0;
      grid-template-areas: '. ';
    }
  }
`;

export const SLinkShop = styled(StyleModal)``;

export default WrapDashboard;
