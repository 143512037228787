import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  TabsContent,
  Text,
  DatePicker,
} from '@components/index';
import { InputText, WrapContentTab } from './style';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import VALIDATE from '@helpers/validate';
import { Col, Row } from 'styled-bootstrap-grid';

import ITruoc from './icons/cmnd-truoc.svg';
import ISau from './icons/cmnd-sau.svg';
import IAvatar from './icons/avatar.svg';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import { HandleError, HandleSuccess } from '@components/HandleResponse';
import { publicURL } from '@helpers/index';
import { disableStatus } from './store/helper';
import { useSelector } from 'react-redux';
import { selectAppStore } from '@containers/App/store/selecters';

type TList = {
  name: string;
  key: string;
  require?: boolean | string;
  validate?: any;
};

const list: TList[] = [
  { name: 'Họ', key: 'firstName', require: VALIDATE.REQUIRE, validate: null },
  { name: 'Tên', key: 'lastName', require: VALIDATE.REQUIRE, validate: null },
  {
    name: 'Ngày sinh',
    key: 'birthday',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  {
    name: 'Số điện thoại',
    key: 'mobile',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  {
    name: 'Địa chỉ',
    key: 'address',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  // { name: 'Email', key: 'email', require: VALIDATE.REQUIRE, validate: null },
  {
    name: 'Số CMND/Hộ chiếu',
    key: 'identityCard',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkAbleImage = (status: any) =>
  status === 'reject' || !status ? VALIDATE.REQUIRE : false;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum TTypeImage {
  passport = 'passport',
  identityCard = 'identityCard',
}
export default function Contact({ callAPI }: { callAPI: any }) {
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const [user_data, setUserData] = useState<any>({});
  const { user } = useSelector(selectAppStore);
  // console.log(user);

  const [preImages, setPreImages] = useState<{
    [key: string]: any;
  }>({
    truoc: ITruoc,
    sau: ISau,
    avatar: IAvatar,
  });

  const onSumit = (data: any) => {
    // console.log('handleSubmit', data);
    const formData = new FormData();

    for (const property in data) {
      if (
        property.includes('identityCardImage') ||
        property.includes('passportImage')
      ) {
        formData.append('image', data[property][0]);
      } else if (property.includes('birthday')) {
        formData.append(property, new Date(data[property]).toISOString());
      } else formData.append(property, data[property]);
    }
    formData.append('typeImage', data['typeImage'] || 'identityCard');

    requestInterToken({
      method: 'PUT',
      url: API_URL.VERIFY.PUT_INFO,
      data: formData,
    })
      .then(HandleSuccess)
      .catch(HandleError);
  };

  const [status, setStatus] = useState<'active' | 'reject' | 'wait' | null>(
    null,
  );

  const setImg = (e: any, key: string) => {
    const file = e.target.files[0];
    if (file) {
      const path = URL.createObjectURL(file);
      setPreImages({
        ...preImages,
        [key]: path,
      });
    }
  };

  useEffect(() => {
    requestToken({ method: 'GET', url: API_URL.VERIFY.GET_INFO }).then(res => {
      // console.log(res.data);
      const data = res.data;
      if (data) {
        setValue('firstName', data['firstName']);
        setValue('lastName', data['lastName']);
        setValue('address', data['address']);
        setValue(
          'birthday',
          data['birthday'] ? new Date(data['birthday']) : null,
        );
        setValue('identityCard', data['identityCard']);
        setValue('mobile', data['mobile']);
        setValue('typeImage', data['typeImage']);

        // identityCardImage vs passportImage
        // console.log(data['identityCardImage'], data['passportImage']);

        if (data['identityCardImage']) {
          setPreImages({
            identityCardImage0: data['identityCardImage'][0]?.path
              ? publicURL(data['identityCardImage'][0]?.path)
              : ITruoc,
            identityCardImage1: data['identityCardImage'][1]?.path
              ? publicURL(data['identityCardImage'][1]?.path)
              : ISau,
            identityCardImage2: data['identityCardImage'][2]?.path
              ? publicURL(data['identityCardImage'][2]?.path)
              : IAvatar,
          });
        } else if (data['passportImage']) {
          setPreImages({
            passportImage0: data['passportImage'][0]?.path
              ? publicURL(data['passportImage'][0]?.path)
              : ISau,
            passportImage1: data['passportImage'][1]?.path
              ? publicURL(data['passportImage'][1]?.path)
              : IAvatar,
          });
        }
        setStatus(data.status);
      }
      setUserData(data);
    });
    // .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAPI]);

  // console.log('status', status);

  return (
    <WrapContentTab>
      <h2>
        {status === 'wait'
          ? 'Đang chờ xác nhận'
          : status === 'reject'
          ? 'Thông tin bị từ chối, hãy gửi lại '
          : ''}
      </h2>
      <form onSubmit={handleSubmit(onSumit)} className="form-data">
        <Row>
          {list.map((t, index) => {
            if (t.key === 'firstName') {
              return (
                <Col key={index} md={6} col={12}>
                  <InputText>
                    <p className="name">{t.name}</p>
                    <Input
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status)
                          ? VALIDATE.REQUIRE
                          : false,
                        validate: t.validate,
                      })}
                      name={t.key}
                      defaultValue={user.firstName}
                      className="input"
                    />
                  </InputText>
                  <ErrorMessage
                    errors={errors}
                    name={t.key}
                    render={Text.ErrorMessage}
                  />
                </Col>
              );
            } else if (t.key === 'lastName') {
              return (
                <Col key={index} md={6} col={12}>
                  <InputText>
                    <p className="name">{t.name}</p>
                    <Input
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status)
                          ? VALIDATE.REQUIRE
                          : false,
                        validate: t.validate,
                      })}
                      name={t.key}
                      defaultValue={user.lastName}
                      className="input"
                    />
                  </InputText>
                  <ErrorMessage
                    errors={errors}
                    name={t.key}
                    render={Text.ErrorMessage}
                  />
                </Col>
              );
            } else if (t.key === 'birthday') {
              return (
                <Col key={index} md={6} col={12}>
                  <InputText>
                    <p className="name">{t.name}</p>
                    <Controller
                      control={control}
                      name={t.key}
                      render={props => (
                        <DatePicker
                          {...props}
                          onDayChange={props.onChange}
                          inputProps={{ disabled: disableStatus(status) }}
                        />
                      )}
                    />
                  </InputText>
                  <ErrorMessage
                    errors={errors}
                    name={t.key}
                    render={Text.ErrorMessage}
                  />
                </Col>
              );
            } else {
              return (
                <Col key={index} md={6} col={12}>
                  <InputText>
                    <p className="name">{t.name}</p>
                    <Input
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status)
                          ? VALIDATE.REQUIRE
                          : false,
                        validate: t.validate,
                      })}
                      name={t.key}
                      className="input"
                    />
                  </InputText>
                  <ErrorMessage
                    errors={errors}
                    name={t.key}
                    render={Text.ErrorMessage}
                  />
                </Col>
              );
            }
          })}
        </Row>

        <div className="upload">
          <div className="text">Upload ảnh</div>
          <TabsContent.TabsComponent
            // tabActive={getValues("")}
            onChangeTab={tab => {
              if (tab === 0) {
                setValue('typeImage', TTypeImage.identityCard);
                if (user_data['identityCardImage']) {
                  setPreImages({
                    identityCardImage0: user_data['identityCardImage'][0]
                      ? publicURL(user_data['identityCardImage'][0]?.path)
                      : ITruoc,
                    identityCardImage1: user_data['identityCardImage'][1]
                      ? publicURL(user_data['identityCardImage'][1]?.path)
                      : ISau,
                    identityCardImage2: user_data['identityCardImage'][2]
                      ? publicURL(user_data['identityCardImage'][2]?.path)
                      : IAvatar,
                  });
                }
              } else {
                setValue('typeImage', TTypeImage.passport);
                if (user_data['passportImage']) {
                  setPreImages({
                    passportImage0: user_data['passportImage'][0]
                      ? publicURL(user_data['passportImage'][0]?.path)
                      : ISau,
                    passportImage1: user_data['passportImage']
                      ? publicURL(user_data['passportImage'][1]?.path)
                      : IAvatar,
                  });
                }
              }
            }}
            control={['CMND/CCCD', 'Hộ chiếu']}
            content={[
              <div className="upload-list-images">
                <div className="item">
                  <div className="title">CMND mặt trước</div>
                  <div className="img">
                    <img
                      src={preImages.identityCardImage0 || IAvatar}
                      alt="CMND mặt trước"
                    />
                  </div>
                  <label className="input">
                    Chọn file
                    <input
                      type="file"
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status),
                      })}
                      name="identityCardImage0"
                      onChange={e => setImg(e, 'identityCardImage0')}
                    />
                  </label>
                  <ErrorMessage
                    errors={errors}
                    name={'identityCardImage0'}
                    render={Text.ErrorMessage}
                  />
                </div>
                <div className="item">
                  <div className="title">CMND mặt sau</div>
                  <div className="img">
                    <img
                      src={preImages.identityCardImage1 || IAvatar}
                      alt="CMND mặt sau"
                    />
                  </div>
                  <label className="input">
                    Chọn file
                    <input
                      type="file"
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status),
                      })}
                      name="identityCardImage1"
                      onChange={e => setImg(e, 'identityCardImage1')}
                    />
                  </label>
                  <ErrorMessage
                    errors={errors}
                    name={'identityCardImage1'}
                    render={Text.ErrorMessage}
                  />
                </div>
                <div className="item">
                  <div className="title">Ảnh selfie cầm CMND/CCCD</div>
                  <div className="img">
                    <img
                      src={preImages.identityCardImage2 || IAvatar}
                      alt="Ảnh chân dung"
                    />
                  </div>
                  <label className="input">
                    Chọn file
                    <input
                      type="file"
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status),
                      })}
                      name="identityCardImage2"
                      onChange={e => setImg(e, 'identityCardImage2')}
                    />
                  </label>
                  <ErrorMessage
                    errors={errors}
                    name={'identityCardImage2'}
                    render={Text.ErrorMessage}
                  />
                </div>
              </div>,
              // hộ chiếu
              <div className="upload-list-images">
                <div className="item">
                  <div className="title">Ảnh hộ chiếu</div>
                  <div className="img">
                    <img
                      src={preImages.passportImage0 || IAvatar}
                      alt="Ảnh hộ chiếu"
                    />
                  </div>
                  <label className="input">
                    Chọn file
                    <input
                      type="file"
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status),
                      })}
                      name="passportImage0"
                      onChange={e => setImg(e, 'passportImage0')}
                    />
                  </label>
                  <ErrorMessage
                    errors={errors}
                    name={'passportImage0'}
                    render={Text.ErrorMessage}
                  />
                </div>
                <div className="item">
                  <div className="title">Ảnh selfie cầm Hộ chiếu</div>
                  <div className="img">
                    <img
                      src={preImages.passportImage1 || IAvatar}
                      alt="Ảnh selfie cầm Hộ chiếu"
                    />
                  </div>
                  <label className="input">
                    Chọn file
                    <input
                      type="file"
                      disabled={disableStatus(status)}
                      ref={register({
                        required: checkAbleImage(status),
                      })}
                      name="passportImage1"
                      onChange={e => setImg(e, 'passportImage1')}
                    />
                  </label>
                  <ErrorMessage
                    errors={errors}
                    name={'passportImage1'}
                    render={Text.ErrorMessage}
                  />
                </div>
              </div>,
            ]}
          />
        </div>

        <div className="btn-submit">
          <Button
            style={{ minWidth: '150px' }}
            type="submit"
            disabled={disableStatus(status)}
          >
            Lưu
          </Button>
        </div>
      </form>
    </WrapContentTab>
  );
}
