/**
 *
 * SectionCollapse
 * make by phamthainb
 */
import React, { ReactChild, ReactChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import theme from '@styles/theme';

import { useSpring, animated } from 'react-spring';
interface Props {
  title: string;
  active?: boolean;
  children: ReactChild | ReactChildren;
  onShow?: Function;
  onDisable?: Function;
}

const SectionCollapse = ({
  title,
  active,
  children,
  onShow,
  onDisable,
}: Props) => {
  const [state, setstate] = useState(active || false);
  const propsSpring = useSpring({ height: state ? 'auto' : 0 });

  useEffect(() => {
    if (state) {
      onShow && onShow();
    } else {
      onDisable && onDisable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (active) setstate(active);
  }, [active]);

  return (
    <SSectionCollapse active={state}>
      <div className="collapse-head" onClick={() => setstate(!state)}>
        {title}
        {state ? <IActive /> : <IInActive />}
      </div>
      <div className={`collapse-content ${state ? 'show' : ''}`}>
        <animated.div style={propsSpring}>{children}</animated.div>
      </div>
    </SSectionCollapse>
  );
};

// type style
type PropsType = { active: boolean };

export const SSectionCollapse = styled.div<PropsType>`
  user-select: none;

  .collapse-head {
    cursor: pointer;
    height: 44px;
    background: ${props =>
      !props.active ? theme.color.line : theme.color.logo};
    border-radius: 2px;
    padding: 12px 34px;
    font-size: 16px;
    line-height: 19px;
    color: ${props =>
      !props.active ? theme.color.text_chinh : theme.color.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
  }
  .collapse-content {
    display: none;
    /* padding: 32px; */
    background: #ffffff;
    border: 1px solid #e3e8f0;
    box-sizing: border-box;
    border-radius: 2px;

    &.show {
      display: block;
    }
  }
`;

export default SectionCollapse;

const IActive = () => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.706633 2.4141L3.29663 5.0041C3.68663 5.3941 4.31663 5.3941 4.70663 5.0041L7.29663 2.4141C7.92663 1.7841 7.47663 0.704102 6.58663 0.704102L1.40663 0.704102C0.516633 0.704102 0.0766335 1.7841 0.706633 2.4141Z"
      fill="white"
    />
  </svg>
);
const IInActive = () => (
  <svg
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.41361 7.29337L5.00361 4.70337C5.39361 4.31337 5.39361 3.68337 5.00361 3.29337L2.41361 0.703367C1.78361 0.0733666 0.703613 0.523367 0.703613 1.41337L0.703613 6.59337C0.703613 7.48337 1.78361 7.92337 2.41361 7.29337Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);
