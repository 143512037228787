import React, { useEffect } from 'react';
import { Layout } from '@components/index';
import ROUTES, { ItemRoute } from '@configs/routes';
import { NavLink, useLocation } from 'react-router-dom';
import useMediaQuery from '@hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { updateUI } from '@containers/App/store/actions';
import { selectAppStore } from '@containers/App/store/selecters';

import IClose from '@assets/images/close.svg';
import { WrapSideBar } from '@components/Layout/SideBar';
import useWindowPosition from '@hooks/useWindowPosition';

export default function SideBar() {
  const { width } = useMediaQuery();
  const dispatch = useDispatch();
  const { ui } = useSelector(selectAppStore);

  useEffect(() => {
    dispatch(updateUI({ ...ui, sidebar: false, overlay: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  // disable scroll when sidebar show on mobile
  useEffect(() => {
    if (ui.sidebar) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'auto';
  }, [ui.sidebar]);

  const closeSideBar = () => {
    dispatch(updateUI({ ...ui, sidebar: false, overlay: false }));
  };

  const location = useLocation();
  React.useEffect(() => {
    closeSideBar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const pst = useWindowPosition();
  return (
    <WrapSideBar>
      <span className="psu" style={{ height: pst < 100 ? '100px' : 0 }}></span>
      <Layout.SideBar show={ui.sidebar}>
        <span
          className="close"
          onClick={() => {
            closeSideBar();
          }}
        >
          <img src={IClose} alt="close" />
        </span>
        <div className="nav">
          {ROUTES.DASHBROAD.map((route, index) => (
            <CustomLink key={index} {...route} />
          ))}
        </div>
        <div className="footer">
          © 2021 - Bản quyền thuộc về Công ty CP Bytesoft Việt Nam
        </div>
      </Layout.SideBar>
    </WrapSideBar>
  );
}

const CustomLink = (props: ItemRoute) => {
  const { name, path, icon } = props;
  if (path) {
    return (
      <NavLink
        exact
        className="item"
        activeClassName="active"
        to={path}
        style={{ textDecoration: 'none' }}
      >
        <div className="item_icon">{icon()}</div>
        <div className="item_text">{name}</div>
      </NavLink>
    );
  } else {
    // has child
    return <NavHasChild {...props} />;
  }
};

const NavHasChild = ({ name, path, icon, child }: ItemRoute) => {
  const { pathname } = useLocation();
  const pathChilds = child?.map(c => c.path);

  return (
    <div className={`item ${pathChilds?.includes(pathname) ? 'active' : ''}`}>
      <div className="item_icon">{icon()}</div>
      <div className="item_text">{name}</div>
      <div className="child-item" style={{ transitionDelay: '0.3s' }}>
        {child &&
          child.map(
            (c, j) =>
              c.path && (
                <NavLink
                  key={j}
                  className="child-item__item"
                  activeClassName="active"
                  to={c?.path}
                >
                  <div className="child-item__text">{c.name}</div>
                </NavLink>
              ),
          )}
      </div>
    </div>
  );
};
