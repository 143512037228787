import React, { useEffect, useRef, useState } from 'react';
import INotify from '@assets/images/icon-notifications.png';
import styled from 'styled-components';
import { requestToken } from '@api/axios';
import API_URL from '@api/url';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifiApp, setCount } from '@containers/App/store/actions';
import { selectAppStore } from '@containers/App/store/selecters';
import { mixinsScrollBar, mixinsScrollBarNone } from '@styles/mixins';
import moment from 'moment';
import useDetectClickOutSide from '@hooks/useDetectClickOutSide';
import { NavLink, useHistory } from 'react-router-dom';
import useMediaQuery from '@hooks/useMediaQuery';

export default function Notification() {
  const dispatch = useDispatch();
  const { countO, data } = useSelector(selectAppStore);
  const [show, setShow] = useState(false);

  useEffect(() => {
    requestToken({
      method: 'GET',
      url: `${API_URL.NOTIFICATION.GETCOUNTNOTIFI}`,
    }).then(res => {
      dispatch(setCount(res.data));
    });
    // onGetData
    onGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGetData = () => {
    dispatch(getNotifiApp());
  };
  const ref = useRef(null);
  const refParent = useRef(null);

  useDetectClickOutSide({
    ref: ref,
    handler: () => {
      setShow(false);
    },
    refParent: refParent,
    handlerParent: () => {
      setShow(!show);
    },
  });

  const history = useHistory();
  const { width } = useMediaQuery();

  return (
    <SNotification>
      {width < 768 ? (
        <div
          onClick={() => {
            history.push('/dashboard/notification');
          }}
          className="info-user__child notify"
        >
          <img className="icon" src={INotify} alt="icon notify" />
          {Number(countO.count) > 0 && (
            <span className="number-count">{countO.count}</span>
          )}
        </div>
      ) : (
        <div ref={refParent} className="info-user__child notify">
          <img className="icon" src={INotify} alt="icon notify" />
          {Number(countO.count) > 0 && (
            <span className="number-count">{countO.count}</span>
          )}
        </div>
      )}

      <div ref={ref} className={`drop-list ${show && 'show'}`}>
        <div className="drop-list__header">
          <p>THÔNG BÁO</p>
          {Number(countO.count) > 0 && (
            <p className="count_notify">{`${countO.count} Mới`}</p>
          )}
        </div>
        <div className="drop-list__content">
          {data.length > 0 ? (
            data.map((d, index) => {
              return (
                <div key={index} className={`item ${d.statusNotification}`}>
                  <p className={`title `}>{d.title}</p>
                  <p className="content">{d.content}</p>
                  <p className="time">{moment(d.updatedAt).fromNow()}</p>
                </div>
              );
            })
          ) : (
            <span
              className="nothing"
              style={{ textAlign: 'center', paddingLeft: '15px' }}
            >
              Không có thông báo nào.
            </span>
          )}
        </div>

        <NavLink
          to="/dashboard/notification"
          className="drop-list__footer"
          onClick={() => setShow(!show)}
        >
          Xem thêm
        </NavLink>
      </div>
    </SNotification>
  );
}
const SNotification = styled.div`
  position: relative;
  p {
    margin: 0;
  }
  .drop-list {
    &.show {
      max-height: 450px;
      overflow: unset;

      .drop-list__content {
        max-height: 300px;
      }
    }

    max-height: 0;
    color: #828282;
    top: calc(100% + 15px);
    position: absolute;
    content: '';
    transition: all 0.3s ease;
    width: 347px;
    background: #ffffff;
    border-radius: 10px;
    right: 0;
    overflow: hidden;
    z-index: 1;
    box-shadow: 5px 10px 20px rgba(113, 119, 150, 0.1);
    ${mixinsScrollBarNone};

    &::before {
      content: '';
      position: absolute;
      right: 28px;
      top: -8px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      background: white;
      z-index: 1;
      transform: rotate(45deg);
    }

    &__header {
      padding: 12px 24px;
      position: relative;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      text-transform: uppercase;
      color: #828282;

      .count_notify {
        position: absolute;
        width: 40px;
        height: 20px;
        background: #ff6565;
        border-radius: 2px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 21px;
        color: #ffffff;

        right: 31px;
        top: 12px;
        padding: 0;
        text-align: center;
      }
    }

    &__content {
      max-height: 0;
      padding-bottom: 12px;
      transition: all 0.3s ease;
      overflow: auto;
      ${mixinsScrollBar};

      span.nothing {
        text-align: center;
      }

      .item {
        cursor: pointer;
        padding: 16px 24px;
        border-top: 1px solid #e3e8f0;

        &.new {
          .title {
            color: #828282;
          }
        }

        &.read {
          .title {
            color: #ff8a00;
          }
        }

        .title {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #828282;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .content {
          font-weight: 300;
          font-size: 12px;
          line-height: 21px;
          color: #828282;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .time {
          font-style: normal;
          font-weight: 300;
          font-size: 10px;
          line-height: 21px;
          color: #bdbdbd;
        }
      }
    }

    &__footer {
      all: unset;
      display: block;
      border-top: 2px solid #e3e8f0;
      padding: 12px;
      text-align: center;
      cursor: pointer;
    }
  }
`;
