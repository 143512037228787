import { HandleError } from '@components/HandleResponse';
import Pagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTableWithDraw from './DataTableWithDraw';
import { getDataWithDraw, setData, setPaging } from './store/actions';
import { selectTransactionListStore } from './store/selecters';
import { SSelectedTab } from './style';

export default function WithDrawTab({ type }: { type: string }) {
  const { pagination, data, total, search } = useSelector(
    selectTransactionListStore,
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getDataWithDraw({ pagination, type, search })
      .then(res => {
        dispatch(setData({ data: res.data, total: res.data.meta.total }));
        setLoading(false);
      })
      .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, search]);

  return (
    <SSelectedTab>
      <DataTableWithDraw data={data} loading={loading} />
      {(total || 0) / pagination.pageSize >= 1 && (
        <Pagination
          onChange={(pageNumber: number) => {
            dispatch(setPaging({ ...pagination, pageNumber }));
          }}
          current={pagination.pageNumber}
          pageSize={pagination.pageSize}
          total={total}
        />
      )}
    </SSelectedTab>
  );
}
