/*
 *
 * AccountVerify style css file
 * make by phamthainb
 */

import theme from '@styles/theme';
import styled from 'styled-components';
import { Style as SDatePicker } from "@components/Input/DatePicker";

type TInputText = {
  minWidth?: number;
};

export const InputText = styled.label.attrs(props => ({
  className: 'label',
})) <TInputText>`
  position: relative;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;

  p.name {
    min-width: ${props => (props.minWidth ? props.minWidth + 'px' : '150px')};
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.color.text_phu};
    height: 100%;
    padding: 0;
    margin: 0;
    margin-right: 24px;
  }

  ${SDatePicker} {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    p.name {
      width: 100%;
      margin: 0;
      padding-bottom: 12px;
    }
  }
`;

const WrapAccountVerify = styled.div`
  .form-data {
    .btn-submit {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
    .upload {
      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #5f6169;
        padding-bottom: 8px;
      }

      .upload-list-images {
        padding: 24px 12px 32px;
        display: flex;
        flex-direction: row;
        gap: 32px;
        justify-content: center;
        align-items: start;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .title {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            color: #828282;
            text-align: left;
            width: 100%;
            margin-bottom: 4px;
          }

          .img {
            width: 217px;
            height: 124px;
            border: 1px solid #e3e8f0;
            box-sizing: border-box;
            border-radius: 2px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .input {
            background: #5f6169;
            border-radius: 2px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #ffffff;
            margin-top: 20px;
            padding: 7px;
            width: 100%;

            input {
              display: none;
            }
          }
        }
      }

      @media (max-width: 1199px) {
        .upload-list-images {
          flex-wrap: wrap;
        }
      }
    }
  }
`;

export const WrapContentTab = styled.div`
  padding: 72px 112px 183px;

  .upload {
    .control_tab {
      margin-bottom: 16px;
      display: flex;
      .control_tab_item {
        background: #E3E8F0;
        border-radius: 8px 8px 0px 0px;
        padding: 8px 22px;
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 46px 46px 100px;
  }

  @media (max-width: 991px) {
    padding: 32px 44px;
  }
`;

export default WrapAccountVerify;
