import Button from '@components/Button';
import React from 'react';
import { SHandle } from './style';
import { useDispatch, useSelector } from 'react-redux';
import ShowSelect from '@components/Input/ShowSelect';
import Print from '@assets/images/print-icon.svg';
import { selectOrderStore } from './store/selecters';
import { changePage } from './store/actions';
import { exportToCSV, getDataExport, mapDataExport } from '@helpers/exportExcel';
import API_URL from '@api/url';
import { convertDateWithTime, convertStatus, money } from '@helpers/format';


const HeadFileExport = [
  { name: "Mã đơn hàng", key: "codeBill" },
  { name: "Thời gian yêu cầu", key: "createdAt", callback: (val: any) => convertDateWithTime(val) ?? "" },
  { name: "Thời gian giao dịch ", key: "transactioncreatedAt", callback: (val: any) => convertDateWithTime(val) ?? "" },
  { name: "Trạng thái", key: "transactionsStatus", callback: (val: any) => convertStatus(val) },
  { name: "Sản phẩm", key: "nameProduct" },
  { name: "Số lượng", key: "quantity" },
  { name: "Đơn giá", key: "priceProduct", callback: (val: any) => money(val, undefined, true) },
  {
    name: "Thành tiền", key: "", callback: (value: any) => {
      return money(Number(value?.priceProduct) * Number(value?.quantity), undefined, true);
    }
  },
  { name: "Giảm giá", key: "moneyDiscount", callback: (val: any) => money(val, undefined, true) },
  {
    name: "Phí giao dịch", key: "", callback: (val: any) => {
      let fee = Number(val?.transactionFee) * Number(val?.money) / 100
      return money(fee);
    }
  },
  { name: "Tổng tiền KH thanh toán", key: "money", callback: (val: any) => money(val, undefined, true) },
  { name: "Tên KH", key: "customerName" },
  { name: "SĐT", key: "customerMobile" },
  { name: "Ngày sinh", key: "customerBirthday", callback: (val: any) => val ? new Date(val).toLocaleDateString('vi-VN') : "" },
  { name: "Địa chỉ ", key: "customerAddress" },
];


export default function Handle() {
  const { pagination, total, search } = useSelector(selectOrderStore);
  const dispatch = useDispatch();

  const exportData = async () => {
    const res = await getDataExport({
      total: total,
      url: API_URL.ORDER.GET,
      search,
    });
    //console.log("res", res);

    const mapData = mapDataExport(HeadFileExport, res);
    exportToCSV(mapData, 'đơn hàng');
  };


  const onExport = async () => {
    //console.log("export");
    await exportData();
  };

  return (
    <SHandle>

      <Button className="print" onClick={onExport}>
        <img src={Print} alt="export" />
      </Button>

      <div className="select">
        <label>Hiển thị</label>
        <ShowSelect
          defaultValue={{
            label: "10",
            value: "10",
          }}
          onChange={val => {
            //console.log(123);

            let pageSize = pagination.pageSize;
            if (val && parseInt(val.value) !== pageSize) {
              pageSize = parseInt(val.value);
              dispatch(changePage({ ...pagination, pageSize }));
            }
          }}
        />
      </div>
    </SHandle>
  );
}
