import React, { useEffect, useState } from 'react';
import { Button, Input, Text } from '@components/index';
import { InputText, WrapContentTab } from './style';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import VALIDATE from '@helpers/validate';
import { Col, Row } from 'styled-bootstrap-grid';

import ITruoc from './icons/cmnd-truoc.svg';
import ISau from './icons/cmnd-sau.svg';
import IAvatar from './icons/avatar.svg';
import { HandleError, HandleSuccess } from '@components/HandleResponse';
import { requestInterToken, requestToken } from '@api/axios';
import API_URL from '@api/url';
import AsyncSelect from 'react-select/async';
import { stylesCustom } from '@components/Input/SelectBase';
import { clearParams } from '@helpers/format';
import { publicURL } from '@helpers/index';
import { disableStatus } from './store/helper';
import _ from 'lodash';

type TList = {
  name: string;
  key: string;
  require?: boolean | string;
  validate?: any;
};

const list: TList[] = [
  {
    name: 'Loại hình doanh nghiệp',
    key: 'enterpriseType',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  {
    name: 'Tên doanh nghiệp',
    key: 'name',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  {
    name: 'Số giấy phép ĐKKD',
    key: 'license',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  {
    name: 'Địa chỉ nơi ĐKKD',
    key: 'address',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  {
    name: 'Số điện thoại',
    key: 'mobile',
    require: VALIDATE.REQUIRE,
    validate: null,
  },
  { name: 'Email', key: 'email', require: VALIDATE.REQUIRE, validate: null },
];

const customStyles = {
  ...stylesCustom(),
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    background: '#e3e8f0',
    borderRadius: '2px',
  }),
  control: (provided: any) => ({
    ...provided,
    border: 'none',
    background: '#e3e8f0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    padding: '0 16px',
    color: '#3A3B42',
    paddingLeft: '8px',
  }),
};

const loadOptions = (inputValue: string, callback: any) => {
  requestToken({
    method: 'GET',
    url: API_URL.VERIFY.GET_ENTERPRISE_TYPE,
    params: clearParams({
      order: 'ASC',
      page: 1,
      take: 10,
      keyword: inputValue,
    }),
  })
    .then(res => {
      callback(
        res.data.data.map((item: any) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      );
    })
    .catch(HandleError);
};

export default function Business({ callAPI }: { callAPI: any }) {
  const { register, handleSubmit, errors, control, setValue } = useForm();

  const [preImages, setPreImages] = useState<{
    truoc: any;
    sau: any;
    avatar: any;
  }>({
    truoc: ITruoc,
    sau: ISau,
    avatar: IAvatar,
  });

  const onSumit = (data: any) => {
    // console.log('handleSubmit', data);
    const { enterpriseType, ...form } = data;
    const formData = new FormData();

    for (const property in form) {
      if (property === 'truoc' || property === 'sau' || property === 'avatar') {
        formData.append('files', form[property][0]);
      } else formData.append(property, form[property]);
    }
    formData.append('enterpriseType', enterpriseType.value);

    requestInterToken({
      method: 'PUT',
      url: API_URL.VERIFY.PUT_ENTERPRISE,
      data: formData,
    })
      .then(HandleSuccess)
      .catch(HandleError);
  };

  const setImg = (e: any, key: 'truoc' | 'sau' | 'avatar') => {
    const file = e.target.files[0];
    const path = URL.createObjectURL(file);
    setPreImages({
      ...preImages,
      [key]: path,
    });
  };
  const [status, setStatus] = useState<'active' | 'reject' | 'wait' | null>(
    null,
  );

  useEffect(() => {
    requestToken({ method: 'GET', url: API_URL.VERIFY.GET_ENTERPRISE }).then(
      res => {
        // console.log(res.data);
        const data = res.data;
        if (Object.keys(data).length > 0) {
          list.forEach(item => {
            setValue(item.key, data[item.key]);
          });

          setValue('enterpriseType', {
            label: data['enterpriseType'].name,
            value: data['enterpriseType'].id,
          });
          // identityCardImage
          if (data['media'])
            setPreImages({
              truoc: data['media'][0]?.path
                ? publicURL(data['media'][0]?.path)
                : ITruoc,
              sau: data['media'][1]?.path
                ? publicURL(data['media'][1]?.path)
                : ISau,
              avatar: data['media'][2]?.path
                ? publicURL(data['media'][2]?.path)
                : IAvatar,
            });

          setStatus(data.status);
        }
      },
    );
    // .catch(HandleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAPI]);

  return (
    <WrapContentTab>
      <h2>
        {status === 'wait'
          ? 'Đang chờ xác nhận'
          : status === 'reject'
          ? 'Thông tin bị từ chối, hãy gửi lại '
          : ''}
      </h2>
      <form onSubmit={handleSubmit(onSumit)} className="form-data">
        <Row>
          {list.map((t, index) => {
            if (t.key === 'enterpriseType') {
              return (
                <Col key={index} md={6} col={12}>
                  <InputText>
                    <p className="name">{t.name}</p>
                    <Controller
                      control={control}
                      name={t.key}
                      rules={{ required: VALIDATE.REQUIRE }}
                      render={props => (
                        <AsyncSelect
                          {...props}
                          styles={customStyles}
                          cacheOptions
                          placeholder="Chọn..."
                          loadOptions={_.debounce(loadOptions, 300)}
                          defaultOptions
                          isDisabled={disableStatus(status)}
                        />
                      )}
                    />
                  </InputText>
                  <ErrorMessage
                    errors={errors}
                    name={t.key}
                    render={Text.ErrorMessage}
                  />
                </Col>
              );
            }
            return (
              <Col key={index} md={6} col={12}>
                <InputText>
                  <p className="name">{t.name}</p>
                  <Input
                    disabled={disableStatus(status)}
                    ref={register({
                      required: t.require,
                      validate: t.validate,
                    })}
                    name={t.key}
                    className="input"
                  />
                </InputText>
                <ErrorMessage
                  errors={errors}
                  name={t.key}
                  render={Text.ErrorMessage}
                />
              </Col>
            );
          })}
        </Row>

        <div className="upload">
          <div className="text">Tải lên ảnh giấy phép ĐKKD</div>
          <div className="upload-list-images">
            <div className="item">
              <div className="title">ĐKKD mặt trước</div>
              <div className="img">
                <img src={preImages.truoc} alt="cmnd" />
              </div>
              <label className="input">
                Chọn file
                <input
                  type="file"
                  disabled={disableStatus(status)}
                  ref={register({ required: VALIDATE.REQUIRE })}
                  name="truoc"
                  onChange={e => setImg(e, 'truoc')}
                />
              </label>
              <ErrorMessage
                errors={errors}
                name={'truoc'}
                render={Text.ErrorMessage}
              />
            </div>
            <div className="item">
              <div className="title">ĐKKD mặt sau</div>
              <div className="img">
                <img src={preImages.sau} alt="cmnd" />
              </div>
              <label className="input">
                Chọn file
                <input
                  type="file"
                  disabled={disableStatus(status)}
                  ref={register({ required: VALIDATE.REQUIRE })}
                  name="sau"
                  onChange={e => setImg(e, 'sau')}
                />
              </label>
              <ErrorMessage
                errors={errors}
                name={'sau'}
                render={Text.ErrorMessage}
              />
            </div>
            {/* <div className="item">
              <div className="title">Ảnh chân dung</div>
              <div className="img">
                <img src={preImages.avatar} alt="cmnd" />
              </div>
              <label className="input">
                Chọn file
                <input
                  type="file"
                  disabled={disableStatus(status)}
                  ref={register({ required: VALIDATE.REQUIRE })}
                  name="avatar"
                  onChange={e => setImg(e, 'avatar')}
                />
              </label>
              <ErrorMessage
                errors={errors}
                name={'avatar'}
                render={Text.ErrorMessage}
              />
            </div> */}
          </div>
        </div>

        <div className="btn-submit">
          <Button
            disabled={disableStatus(status)}
            style={{ minWidth: '150px' }}
            type="submit"
          >
            Lưu
          </Button>
        </div>
      </form>
    </WrapContentTab>
  );
}
