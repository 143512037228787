import React from 'react';
import { Button, Layout } from '@components/index';
// import images
import ILogo from '@assets/images/logo.svg';
import DropInforUser from './DropInforUser';
import useMediaQuery from '@hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { updateUI } from '@containers/App/store/actions';
import { selectAppStore } from '@containers/App/store/selecters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Notification from './Notification';
import { useHistory } from 'react-router';

export default function Header() {
  const { width } = useMediaQuery();
  const dispatch = useDispatch();
  const { ui } = useSelector(selectAppStore);
  const history = useHistory();
  return (
    <Layout.Header>
      <div
        className="logo"
        onClick={() => {
          history.push('/');
        }}
      >
        <img src={ILogo} alt="logo" />
      </div>
      <div className="info-user">
        <Notification />

        <DropInforUser />
        {width < 992 && (
          <Button
            onClick={() => {
              dispatch(
                updateUI({ ...ui, sidebar: !ui.sidebar, overlay: true }),
              );
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
        )}
      </div>
    </Layout.Header>
  );
}
