import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { NewsFooter } from '../../../components/Layout/NewsLayout/Footer';
import { ReactComponent as Logo } from '@assets/images/logo.svg';
import { ReactComponent as PCI } from '@assets/images/Home/pci-logo.svg';
import { ReactComponent as MarkIcon } from '@assets/images/Home/mark-icon.svg';
import { ReactComponent as EmailIcon } from '@assets/images/Home/email-icon.svg';
import { ReactComponent as PhoneIcon } from '@assets/images/Home/phone-icon.svg';
// import { ReactComponent as FacebookIcon } from '@assets/images/Home/facebook-icon.svg';
// import { ReactComponent as YoutubeIcon } from '@assets/images/Home/yt-icon.svg';
// import { ReactComponent as IgIcon } from '@assets/images/Home/instagram-icon.svg';
import mcAfee from '@assets/images/Home/mc-afee-logo.png';
import napas from '@assets/images/Home/napas-logo.png';
import iconTMDT from '@assets/images/bo-cong-thuong.svg';

const docs = [
  {
    name: 'Quy chế hoạt động',
    url: '/docs/quyche.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Chính sách bảo vệ thông tin cá nhân',
    url: '/docs/chinhsach_bvttvn.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Cơ chế giải quyết tranh chấp',
    url: '/docs/chinhsach_gqtt.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Quy định quản lý, kiểm soát thông tin',
    url: '/docs/chinhsach_qltt.pdf',
    icon: <EmailIcon />,
  },
];

const docs1 = [
  {
    name: 'Về chúng tôi',
    url: '/docs/about-us.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Liên hệ',
    url: '/docs/contact-us.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Chính sách bảo vệ thông tin cá nhân',
    url: '/docs/policy.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Quy trình đổi trả và hoàn tiền',
    url: '/docs/refund-return-policy.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Quy trình vận chuyển',
    url: '/docs/shipping-policy.pdf',
    icon: <EmailIcon />,
  },
  {
    name: 'Quy chế hoạt động',
    url: '/docs/term-of-service.pdf',
    icon: <EmailIcon />,
  },
];

export default function Footer() {
  return (
    <NewsFooter>
      <Container>
        <Row>
          <div className="border"></div>
        </Row>
      </Container>

      <Container>
        <Row className="row">
          <Col col md={6} sm={12}>
            <div
              className="column column_1"
              // //data-aos="fade-up"
              // //data-aos-duration="1000"
            >
              <Logo className="logo" />
              <h3>Nền tảng Thương Mại Điện Tử Bytepay</h3>
              <p>
                Nền tảng Thương Mại Điện Tử Toàn Diện.
                <br />
                Lựa chọn tối ưu của đối tác bán hàng và người thanh toán.
              </p>
              <div className="investers">
                <PCI />
                {/* <McAfee /> */}
                <img src={mcAfee} alt="logo" />
                {/* <Napas /> */}
                <img src={napas} alt="logo" />
              </div>
            </div>
          </Col>

          <Col col md={6} sm={6} xs={12}>
            <div
              className="column column_2"
              //data-aos="fade-up"
              //data-aos-duration="1000"
              //data-aos-delay="200"
            >
              <h3>Liên hệ</h3>
              <div className="detail">
                <MarkIcon />
                <span>
                  Tầng 5, Tòa nhà văn phòng 1, Tổ hợp Sun Square, số 21 Lê Đức
                  Thọ, Phường Mỹ Đình 2, Quận Nam Từ Liêm, Hà Nội, Việt Nam
                </span>
              </div>
              <div className="detail">
                <PhoneIcon />
                <span>0797999929</span>
              </div>
              <div className="detail">
                <EmailIcon />
                <a
                  style={{
                    textDecoration: 'none',
                    paddingLeft: '12px',
                    color: '#8c95b0',
                  }}
                  href="mailto:info@bytepay.com.vn"
                >
                  info@bytepay.com.vn
                </a>
              </div>
            </div>
          </Col>

          <Col col md={12} sm={6} xs={12}>
            <h3 style={{marginTop: '42px'}}>Tài liệu</h3>
            <Row>
              <Col col md={6} sm={12} xs={12}>
                <div className="column column_2">
                  {docs.map((k, i) => (
                    <div
                      className="detail"
                      key={i}
                      style={{ cursor: 'pointer' }}
                    >
                      {k?.icon}
                      <span onClick={() => window.open(k?.url)}>{k?.name}</span>
                    </div>
                  ))}
                </div>
              </Col>
              <Col col md={6} sm={12} xs={12}>
                <div className="column column_2">
                  {docs1.map((k, i) => (
                    <div
                      className="detail"
                      key={i}
                      style={{ cursor: 'pointer' }}
                    >
                      {k?.icon}
                      <span onClick={() => window.open(k?.url)}>{k?.name}</span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>

          {/* <Col col md={4} sm={6} xs={12}>
            <div
              className="column column_3"
              //data-aos="fade-up"
              //data-aos-duration="1000"
              //data-aos-delay="400"
            >
              <h3>Kết nối với chúng tôi</h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Bytepay"
              >
                <div className="detail">
                  <FacebookIcon />
                  <span>Facebook</span>
                </div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCwzSxzFOEdniv_wOkGiZL7w"
              >
                <div className="detail">
                  <YoutubeIcon />
                  <span>Youtube</span>
                </div>
              </a>
              <p className="right">
                CopyRight 2021/<span>Bytepay</span>
              </p>
            </div>
          </Col> */}
        </Row>
        <Row>
          <a
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            target="_blank"
            rel="noopener noreferrer"
            href="http://online.gov.vn/Home/WebDetails/98028"
          >
            <img
              alt=""
              title=""
              style={{
                maxWidth: '150px',
                width: '150px'
              }}
              src={iconTMDT}
            />
          </a>
        </Row>
        <Row>
          <p
            style={{
              width: '100%',
              textAlign: 'center',
              color: '#8d95b0',
              lineHeight: '28px',
            }}
          >
            CÔNG TY CỔ PHẦN BYTEPAY | MST: 0110016926 | Ngày cấp: 01/06/2022{' '}
            <br />
            Nơi cấp: Sở kế hoạch và Đầu tư Hà Nội | Người đại diện pháp luật:
            Nguyễn Hải Đăng
          </p>
        </Row>
      </Container>
    </NewsFooter>
  );
}
